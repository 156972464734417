import React, { useEffect, useState, useContext } from "react";
import { translations, constants } from "../base.js";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";
import Child from "../components/child/Child.jsx";
import ConfirmDeleteChild from "../components/child/ConfirmDeleteChild.jsx";
import ConfirmModal from "../components/common/ConfirmModal.jsx";

import { AuthContext } from "../context/authContext.js";
import { ChildApi } from "../api/childApi.js";

const FavoritesPage = () => {
    const [records, setChildren] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [childToDelete, setChildToDelete] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const [skip, setSkip] = useState(0);
    const [trigger, setTrigger] = useState(false);

    const { deleteChild, getFavoriteChildren } = ChildApi;

    useEffect(() => {
        async function fetchData() {
            if (!currentUser) {
                return;
            }

            setIsLoading(true);
            setIsUnavailable(false);

            const userId = currentUser ? currentUser.id : null;

            const result = await getFavoriteChildren(
                userId,
                constants.DEFAULTS.PAGE_SIZE,
                skip
            );

            setIsLoading(false);

            if (!result.error) {
                if (result.length > 0) {
                    setChildren((prevRecords) => [...prevRecords, ...result]);
                }

                if (result.length < constants.DEFAULTS.PAGE_SIZE) {
                    // No more records
                    setIsEndOfList(true);
                }
            } else {
                setIsUnavailable(true);
            }
        }

        fetchData();
    }, [skip, currentUser, getFavoriteChildren, trigger]);

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setSkip(records?.length > 0 ? records.length : 0);
    };

    // Sets child with specified id for removal
    const handleDeleteChild = (id) => {
        const selected = records.find((record) => record.id === id);
        setChildToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setChildToDelete(null);
    };

    // Marks selected child as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (childToDelete) {
            const result = await deleteChild(childToDelete.id);

            if (!result.error) {
                const updatedRecords = records.filter(
                    (record) => record.id !== childToDelete.id
                );
                setChildren(updatedRecords);
            }
        }

        setChildToDelete(null);
    };

    return (
        <div className="children-content">
            <h1>{translations.Menu.Favorites}</h1>

            {childToDelete && (
                <ConfirmModal
                    contentComponent={
                        <ConfirmDeleteChild child={childToDelete} />
                    }
                    closeModal={handleCloseModal}
                    confirmDelete={handleDeleteComfirmed}
                />
            )}

            {records.map((child) => (
                <div key={child.id}>
                    <Child child={child} deleteChild={handleDeleteChild} />
                </div>
            ))}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {!isLoading && !isEndOfList && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {isLoading && <Spinner />}

            {!isLoading && isEndOfList && (
                <NoItemsCard isEmpty={records?.length === 0} />
            )}
        </div>
    );
};

export default FavoritesPage;
