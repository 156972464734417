import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { translations, constants } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { UserApi } from "../api/userApi.js";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";

const UsersPage = () => {
    const { currentUser } = useContext(AuthContext);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [topAuthors, setTopAutors] = useState([]);
    const [skip, setSkip] = useState(0);

    const { getTopUsers } = UserApi;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            const result = await getTopUsers(
                constants.DEFAULTS.USERS_PAGE_SIZE,
                skip
            );

            setIsLoading(false);

            if (!result?.error) {
                if (result.length > 0) {
                    setTopAutors((prev) => [...prev, ...result]);
                }

                if (result.length < constants.DEFAULTS.USERS_PAGE_SIZE) {
                    // No more records
                    setIsEndOfList(true);
                }
            } else {
                setIsUnavailable(true);
            }
        };

        fetchData();
    }, [getTopUsers, skip]);

    const handleLoadMore = () => {
        setSkip(topAuthors?.length);
    };

    return (
        <div className="standard-page">
            <h1>{translations.Menu.Users}</h1>

            <div className="users-content">
                {topAuthors.map((user, index) => (
                    <div key={user.id} className="user">
                        <div className="nick">
                            {index + 1}.{" "}
                            <Link to={`/user/${user.id}`}>{user.nick}</Link> (
                            <span className="rating">
                                {user.rating > 1000
                                    ? (user.rating / 1000).toFixed(1) + "k"
                                    : user.rating}
                            </span>
                            )
                        </div>

                        <div className="link">
                            <Link to={`/userchs/${user.id}`}>
                                {translations.Menu.Children}
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={`/userprs/${user.id}`}>
                                {translations.Menu.Parents}
                            </Link>
                        </div>
                        {currentUser && (
                            <div className="link">
                                <Link to={`/messages/${user.id}`}>
                                    {translations.User.SendMessage}
                                </Link>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {!isLoading && !isEndOfList && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {isLoading && <Spinner />}

            {!isLoading && isEndOfList && (
                <NoItemsCard isEmpty={topAuthors?.length === 0} />
            )}
        </div>
    );
};

export default UsersPage;
