import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { translations } from "../base.js";
import { UserApi } from "../api/userApi.js";
import UserBadgesCompact from "../components/badges/UserBadgesCompact.jsx";

const UserOfTheYearWidget = () => {
    const [user, setUser] = useState(null);
    const { getTopUserOfThePreviousYear } = UserApi;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getTopUserOfThePreviousYear();

            if (result && result.id) {
                setUser(result);
            }
        };

        fetchData();
    }, [getTopUserOfThePreviousYear]);

    return (
        user && (
            <div className="widget-container">
                <div className="widget center-content">
                    <div className="widget-content">
                        <div className="heading">
                            {translations.Widgets.UserOfTheYear}
                        </div>
                        <div className="content-large">
                            <Link to={`/user/${user.id}`}>{user.nick}</Link>
                        </div>
                        <UserBadgesCompact badges={user.badges} />
                    </div>
                </div>
            </div>
        )
    );
};

export default UserOfTheYearWidget;
