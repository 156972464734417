import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { RoomApi } from "../api/roomApi.js";
import { checkUserAccess } from "../utils/rooms.js";
import ChildrenPageBase from "./ChildrenPageBase.jsx";
import RoomWidgets from "../components/common/RoomWidgets.jsx";

const RoomChildrenPage = () => {
    const { roomId } = useParams();
    const [room, setRoom] = useState(null);
    const [isAccessAllowed, setIsAccessAllowed] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const title = translations.Menu.RoomChildren;
    const { getRoomInfo } = RoomApi;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getRoomInfo(roomId);

            if (result && !result.error) {
                setRoom(result);

                const canAccess = checkUserAccess(
                    currentUser ? currentUser.id : null,
                    result
                );

                if (canAccess) {
                    setIsAccessAllowed(true);
                } else {
                    setDisplayErrorMessage(true);
                }
            }
        };

        fetchData();
    }, [getRoomInfo, checkUserAccess, currentUser]);

    return (
        <div className="room">
            {isAccessAllowed && (
                <ChildrenPageBase
                    pageTitle={title}
                    showSidebar={true}
                    widgetsComponent={RoomWidgets}
                    roomId={roomId}
                />
            )}
            {displayErrorMessage && (
                <div className="error-message-block">
                    {translations.Errors.RoomAccessDenied}
                </div>
            )}
        </div>
    );
};

export default RoomChildrenPage;
