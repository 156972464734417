import React from "react";
import { useEffect, useState } from "react";
import { ChildApi } from "../api/childApi.js";
import { translations } from "../base.js";
import ChildWidget from "../components/child/ChildWidget.jsx";

const ChildOfTheWeekWidget = () => {
    const [children, setChildren] = useState([]);
    const { getTopChildOfThePreviousWeek } = ChildApi;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getTopChildOfThePreviousWeek();

            if (result && result.length > 0) {
                setChildren(result);
            }
        };

        fetchData();
    }, [getTopChildOfThePreviousWeek]);

    return (
        children &&
        children.length > 0 && (
            <div className="widget-container">
                <div className="widget">
                    <div className="widget-content">
                        <div className="heading">
                            {translations.Widgets.ChildOfTheWeek}
                        </div>
                        <div className="child">
                            {children.map((child) => (
                                <ChildWidget key={child.id} child={child} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default ChildOfTheWeekWidget;
