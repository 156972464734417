import { translations } from "../../base.js";

const NoItemsCard = ({ isEmpty = false }) => {
    return (
        <div className="card">
            <p className="centered smaller">
                {isEmpty
                    ? translations.Messages.NoItems
                    : translations.Messages.NoMoreItems}
            </p>
        </div>
    );
};

export default NoItemsCard;
