import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { translations, constants } from "../base.js";
import { getTimeAgo } from "../utils/dates.js";
import { FeedbackApi } from "../api/feedbackApi.js";
import Spinner from "../components/common/Spinner.jsx";

const FeedbacksPage = () => {
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [lastLoadedId, setLastLoadedId] = useState(0);

    const { getFeedbacks } = FeedbackApi;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            const result = await getFeedbacks(
                constants.DEFAULTS.PAGE_SIZE,
                lastLoadedId
            );

            setIsLoading(false);

            if (!result?.error && result.length > 0) {
                setRecords((prevRecords) => [...prevRecords, ...result]);
            } else if (result.length === 0) {
                setMessage("No more items");
            } else {
                setIsUnavailable(true);
            }
        };

        fetchData();
    }, [getFeedbacks, lastLoadedId]);

    const handleLoadMore = (e) => {
        setLastLoadedId(records[records.length - 1].id);
    };

    return (
        <div className="standard-page">
            <h1>{translations.Menu.Feedbacks}</h1>
            {records.map((item) => (
                <div className="feedback" key={item.id}>
                    <div className="user">
                        <Link to={`/user/${item.userId}`}>{item.nick}</Link>
                    </div>
                    <div className="date">{getTimeAgo(item.created)}</div>
                    <div className="content">
                        {item.content.split("\n").map((item, key) => {
                            return <div key={key}>{item}</div>;
                        })}
                    </div>
                </div>
            ))}

            {message && (
                <div className="message">
                    {translations.Messages.NoMoreItems}
                </div>
            )}

            {isLoading && <Spinner />}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {!isLoading && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}
        </div>
    );
};

export default FeedbacksPage;
