import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { constants, translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { MessageApi } from "../api/messageApi.js";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";
import Message from "../components/message/Message.jsx";
import MessageForm from "../components/message/MessageForm.jsx";
import UserAutocomplete from "../components/controls/UserAutocomplete.jsx";

const MessagesPage = () => {
    const [chats, setChats] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [chatMessages, setChatMessages] = useState([]);
    const [isChatsLoading, setIsChatsLoading] = useState(false);
    const [isMessagesLoading, setIsMessagesLoading] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [trigger, setTrigger] = useState(false);
    const messagesBlockRef = useRef(null);
    const { recipientId } = useParams();

    const { getUserRecentChats, getChatMessages } = MessageApi;

    const navigate = useNavigate();

    if (!currentUser) {
        navigate("/");
    }

    useEffect(() => {
        messagesBlockRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    }, [currentChat]);

    useEffect(() => {
        const fetchData = async () => {
            setIsChatsLoading(true);
            setIsUnavailable(false);

            const userId = currentUser ? currentUser.id : null;

            const result = await getUserRecentChats(
                userId,
                recipientId ?? null
            );

            if (!result.error && result.length > 0) {
                setChats(result);

                let chatId = null;

                if (recipientId) {
                    chatId = parseInt(recipientId, 10);
                } else {
                    chatId = result[0].id;
                }

                setCurrentChat(result.filter((chat) => chat.id === chatId)[0]);
            } else if (result.length === 0) {
                // No more records
            } else {
                setIsUnavailable(true);
            }

            setIsChatsLoading(false);
        };

        fetchData();
    }, [currentUser, getUserRecentChats, recipientId]);

    useEffect(() => {
        const fetchData = async () => {
            if (currentChat) {
                setIsMessagesLoading(true);
                setIsEndOfList(false);

                const userId = currentUser ? currentUser.id : null;

                const result = await getChatMessages(
                    userId,
                    currentChat.id,
                    constants.DEFAULTS.PAGE_SIZE,
                    lastLoadedId
                );

                if (!result.error) {
                    if (result.length > 0) {
                        const messages = result.map((item, key) => ({
                            ...item,
                            recentContent: getMessageRecentContent(
                                item.content
                            ),
                            repliedContent: getMessageRepliedContent(
                                item.content
                            ),
                        }));

                        setChatMessages((prevRecords) => [
                            ...prevRecords,
                            ...messages,
                        ]);
                    }

                    if (result.length < constants.DEFAULTS.PAGE_SIZE) {
                        setIsEndOfList(true);
                    }
                } else {
                    setIsUnavailable(true);
                }

                setIsMessagesLoading(false);
            }
        };

        fetchData();
    }, [currentChat, lastLoadedId, trigger, getChatMessages, currentUser]);

    const handleChatSelected = async (chatId) => {
        if (chatId !== currentChat?.id) {
            setChatMessages([]);
            setLastLoadedId(0);
            setCurrentChat(chats.filter((chat) => chat.id === chatId)[0]);
        }
    };

    const handleChatAdded = (newChat) => {
        if (!chats.find((chat) => chat.id === newChat.id)) {
            setChats((prevChats) => [newChat, ...prevChats]);
        }

        setChatMessages([]);
        setLastLoadedId(0);
        setCurrentChat(newChat);
    };

    const handleLoadMore = () => {
        setLastLoadedId(
            chatMessages.length > 0
                ? chatMessages[chatMessages.length - 1].id
                : 0
        );
    };

    const handleFormSubmitted = (success) => {
        if (success && currentChat) {
            setChatMessages([]);
            setLastLoadedId(0);
            setTrigger((prev) => !prev);
        }
    };

    // Gets the most recent part of the message without replied part
    // This is to manage the older messages when we attached to who history of the messages by default
    // Now split it by the special characters to extract those parts separately
    const getMessageRecentContent = (content) => {
        const splitContent = content.split(">---");
        return splitContent.length > 0 ? splitContent[0] : "";
    };

    // Gets the replied part of the message attached after the most recent message
    // This is to manage the older messages when we attached to who history of the messages by default
    // Now split it by the special characters to extract those parts separately
    const getMessageRepliedContent = (content) => {
        const splitContent = content.split(">---");
        splitContent.shift();
        return splitContent.length > 0 ? splitContent.join("\n") : "";
    };

    return (
        <div className="message-content">
            <h1>{translations.Menu.Messages}</h1>

            <div className="chat-container">
                <div className="chats">
                    <div className="heading">
                        {translations.Chats.RecentChats}
                    </div>
                    <div>
                        <UserAutocomplete
                            handleTriggerParentComponent={handleChatAdded}
                            placeholder={translations.User.FindUser}
                        />
                    </div>
                    {isChatsLoading && <Spinner />}

                    {!isChatsLoading &&
                        chats.length > 0 &&
                        chats.map((chat) => (
                            <div
                                className={
                                    currentChat && currentChat.id === chat.id
                                        ? "chat active"
                                        : "chat"
                                }
                                key={chat.id}
                                onClick={() => {
                                    handleChatSelected(chat.id);
                                }}
                            >
                                {chat.nick}
                            </div>
                        ))}
                </div>
                <div className="chat-messages" ref={messagesBlockRef}>
                    {!isMessagesLoading && currentChat && (
                        <div className="heading">
                            {translations.Chats.ChatWith} {currentChat.nick}
                        </div>
                    )}

                    {!isMessagesLoading && currentChat && (
                        <MessageForm
                            recipientId={currentChat.id}
                            formSubmitted={handleFormSubmitted}
                        />
                    )}

                    {chatMessages.length > 0 &&
                        chatMessages.map((message) => (
                            <Message
                                message={message}
                                chat={currentChat}
                                key={message.id}
                            />
                        ))}

                    {!isMessagesLoading &&
                        !isChatsLoading &&
                        !isEndOfList &&
                        chatMessages.length > 0 && (
                            <div className="filter-buttons">
                                <button onClick={handleLoadMore}>
                                    {translations.Buttons.LoadMore}
                                </button>
                            </div>
                        )}

                    {isMessagesLoading && <Spinner />}

                    {!isMessagesLoading && isEndOfList && (
                        <NoItemsCard isEmpty={chatMessages?.length === 0} />
                    )}
                </div>
            </div>

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}
        </div>
    );
};

export default MessagesPage;
