import React from "react";
import { Link } from "react-router-dom";
import { translations } from "../base.js";

const RoomUsers = ({ room }) => {
    return (
        room &&
        room.users &&
        room.users.length > 0 && (
            <div className="widget-container">
                <div className="widget room">
                    <div className="title">
                        {translations.Rooms.Participants}
                    </div>
                    <div className="widget-content">
                        <div className="menu">
                            {room.users &&
                                room.users.length > 0 &&
                                room.users.map((user) => (
                                    <div className="menu-item" key={user.id}>
                                        <Link to={`/user/${user.id}`}>
                                            {user.nick}
                                        </Link>{" "}
                                        ({user.roomRating})
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default RoomUsers;
