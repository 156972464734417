import React from "react";
import { translations } from "../base.js";
import ParentsPageBase from "./ParentsPageBase.jsx";
import PublicWidgets from "../components/common/PublicWidgets.jsx";

const ParentsPage = () => {
    const title = translations.Menu.Parents;

    return (
        <ParentsPageBase
            pageTitle={title}
            showOrderMenu={true}
            showSidebar={true}
            widgetsComponent={PublicWidgets}
        />
    );
};

export default ParentsPage;
