import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { translations } from "../base.js";
import { CommonApi } from "../api/commonApi.js";
import { getBadgeClass, getBadgeTitle } from "../utils/badges.js";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";

const BadgesPage = () => {
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [badges, setBadges] = useState([]);
    const badgeOrder = [8, 10, 9, 4, 1, 2, 3, 5, 6];

    const { getAllBadges } = CommonApi;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            const result = await getAllBadges();

            setIsLoading(false);

            if (!result?.error) {
                setBadges(result);
            } else {
                setIsUnavailable(true);
            }
        };

        fetchData();
    }, [getAllBadges]);

    return (
        <div className="standard-page">
            <h1>{translations.Menu.Badges}</h1>
            <div className="badges">
                {badgeOrder.map(
                    (id) =>
                        badges[id] && (
                            <div key={id}>
                                <div className={`badge ${getBadgeClass(id)}`}>
                                    <div className="content">
                                        {getBadgeTitle(id)}
                                    </div>
                                </div>

                                <div className="badge-users">
                                    {badges[id].map((b, k) => (
                                        <div className="user" key={k}>
                                            <Link to={`/user/${b.userId}`}>
                                                {b.nick}
                                            </Link>{" "}
                                            x <strong>{b.cnt}</strong>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                )}
            </div>

            {isLoading && <Spinner />}

            {!badges.length && !isLoading && !isUnavailable && (
                <NoItemsCard isEmpty={true} />
            )}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}
        </div>
    );
};

export default BadgesPage;
