import React, { useState, useContext } from "react";
import { translations, constants } from "../base.js";
import { AuthContext } from "../context/authContext.js";

const RegisterPage = () => {
    const [inputs, setInputs] = useState({ nick: "", email: "", password: "" });
    const [errorMessage, setError] = useState("");
    const [successMessage, setSuccess] = useState("");
    const { register } = useContext(AuthContext);

    const checkContent = () => {
        // Allow only letters (any unicode letters), numbers and spaces
        const allowedNickRegex = /^[\p{L}0-9 ]*$/u;
        // Allow only email in correct format
        const allowedEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return (
            !allowedNickRegex.test(inputs.nick) ||
            !allowedEmailRegex.test(inputs.email) ||
            inputs.nick.length < constants.VALIDATION.MIN_CONTENT_SIZE ||
            inputs.nick.length > constants.VALIDATION.MAX_USER_NAME_SIZE ||
            inputs.password.length < constants.VALIDATION.MIN_PASSWORD_SIZE ||
            inputs.password.length > constants.VALIDATION.MAX_PASSWORD_SIZE
        );
    };

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const result = await register(
                inputs.nick,
                inputs.email,
                inputs.password
            );

            if (result.data.success) {
                setSuccess(translations.Auth.UserCreated);
            } else {
                setError(translations.Errors.GeneralError);
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error;

            switch (errorMessage) {
                case "USER_EXISTS":
                    setError(translations.Errors.RegisterUserExists);
                    break;
                case "NICK_EXISTS":
                    setError(translations.Errors.NickExists);
                    break;
                default:
                    setError(translations.Errors.GeneralError);
                    break;
            }
        }
    };

    return (
        <div className="auth">
            <h1>{translations.Auth.Register}</h1>
            {!successMessage && (
                <p className="warning">
                    {translations.Auth.UserNameRules.replace(
                        "{1}",
                        constants.VALIDATION.MIN_CONTENT_SIZE
                    ).replace("{2}", constants.VALIDATION.MAX_USER_NAME_SIZE)}
                    <br />
                    {translations.Auth.PasswordRules.replace(
                        "{1}",
                        constants.VALIDATION.MIN_PASSWORD_SIZE
                    ).replace("{2}", constants.VALIDATION.MAX_PASSWORD_SIZE)}
                </p>
            )}
            {successMessage && <p className="success">{successMessage}</p>}

            {!successMessage && (
                <form autoComplete="off" role="presentation">
                    <input
                        required
                        type="text"
                        placeholder={translations.Auth.Nickname}
                        name="nick"
                        onChange={handleChange}
                        autoComplete="new-nick"
                        maxLength={constants.VALIDATION.MAX_USER_NAME_SIZE}
                    />
                    <input
                        required
                        type="text"
                        placeholder={translations.Auth.Email}
                        name="email"
                        onChange={handleChange}
                    />
                    <input
                        required
                        type="password"
                        placeholder={translations.Auth.Password}
                        name="password"
                        onChange={handleChange}
                        autoComplete="new-password"
                        aria-autocomplete="none"
                        maxLength={constants.VALIDATION.MAX_PASSWORD_SIZE}
                    />

                    <button onClick={handleSubmit} disabled={checkContent()}>
                        {translations.Forms.Submit}
                    </button>

                    {errorMessage && <p className="error">{errorMessage}</p>}
                </form>
            )}
        </div>
    );
};

export default RegisterPage;
