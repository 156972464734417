import React from "react";
import { translations } from "../base.js";

const NotFoundPage = () => {
    return (
        <div>
            <h1>{translations.NotFound.Heading}</h1>
            <center>
                <p>{translations.NotFound.Content}</p>
            </center>
        </div>
    );
};

export default NotFoundPage;
