import RandomChildWidget from "../../widgets/RandomChildWidget.jsx";
import UserOfTheWeekWidget from "../../widgets/UserOfTheWeekWidget.jsx";
import UserOfTheMonthWidget from "../../widgets/UserOfTheMonthWidget.jsx";
import UserOfTheYearWidget from "../../widgets/UserOfTheYearWidget.jsx";
import ChildOfTheWeekWidget from "../../widgets/ChildOfTheWeekWidget.jsx";
import OnlineUsersWidget from "../../widgets/OnlineUsersWidget.jsx";
import TopUsersWidget from "../../widgets/TopUsersWidget.jsx";
import DynamicRatingsWidget from "../../widgets/DynamicRatingsWidget.jsx";
import CurrentUserWidget from "../../widgets/CurrentUserWidget.jsx";

const PublicWidgets = () => {
    return (
        <div className="sidebar-widgets">
            <CurrentUserWidget />
            <OnlineUsersWidget />
            <RandomChildWidget />
            <DynamicRatingsWidget />
            <ChildOfTheWeekWidget />
            <UserOfTheWeekWidget />
            <UserOfTheMonthWidget />
            <UserOfTheYearWidget />
            <TopUsersWidget />
        </div>
    );
};

export default PublicWidgets;
