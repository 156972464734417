import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const NotificationApi = {
    /**
     * Gets user notifications
     *
     * @param {number} userId current user's id
     * @param {number} lastLoadedId
     * @param {number} count
     */
    getUserNotifications: async (
        userId,
        count = constants.DEFAULTS.PAGE_SIZE,
        lastLoadedId
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/events/notifications/${userId}/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets user notification count
     *
     * @param {number} userId current user's id
     */
    getUserNotificationCount: async (userId) => {
        try {
            const endpointUrl = `${SERVICE_URL}/events/notificationcount/${userId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return 0;
    },
};
