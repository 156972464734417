import React, { useEffect, useState } from "react";
import { NewsApi } from "../api/newsApi.js";
import { Link } from "react-router-dom";
import { translations } from "../base.js";

const NewsWidget = () => {
    const { getNews } = NewsApi;
    const [news, setNews] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getNews(1);

            if (!result?.error && result.length > 0) {
                let content = "";

                result[0].content.split("\n").map((item, key) => {
                    content += `<p key=${key}>${item}</p>`;
                    return content;
                });

                const newsFormatted = { ...result[0], content: content };
                setNews(newsFormatted);
            }
        };

        fetchData();
    }, [getNews]);

    return (
        news && (
            <div className="widget news">
                <div className="title">
                    <div>
                        {translations.News.SingleNews}:{" "}
                        <Link
                            to={`/news/${news.id}`}
                        >{`${news.title} (${news.commentCnt})`}</Link>
                    </div>
                </div>
            </div>
        )
    );
};

export default NewsWidget;
