import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { translations, constants } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { NotificationApi } from "../api/notificationApi.js";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";
import EventNotification from "../components/notification/EventNotification.jsx";

const NotificationsPage = () => {
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [trigger, setTrigger] = useState(false);
    const { getUserNotifications } = NotificationApi;

    const navigate = useNavigate();

    if (!currentUser) {
        navigate("/");
    }

    // Reset children array if the currentUser changed to update the votes, favorites, etc
    useEffect(() => {
        setRecords([]);
    }, [currentUser]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            const userId = currentUser ? currentUser.id : null;

            const result = await getUserNotifications(
                userId,
                constants.DEFAULTS.PAGE_SIZE,
                lastLoadedId
            );

            if (!result.error) {
                if (result.length > 0) {
                    setRecords((prevRecords) => [...prevRecords, ...result]);
                }

                if (result.length < constants.DEFAULTS.PAGE_SIZE) {
                    // No more records
                    setIsEndOfList(true);
                }
            } else {
                setIsUnavailable(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [lastLoadedId, currentUser, getUserNotifications, trigger]);

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setLastLoadedId(
            records?.length > 0 ? records[records.length - 1].id : 0
        );
    };

    return (
        <div className="notification-content">
            <h1>{translations.Menu.Notifications}</h1>

            {records.map((notification) => (
                <div key={notification.id}>
                    <EventNotification eventNotification={notification} />
                </div>
            ))}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {!isLoading && !isEndOfList && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {isLoading && <Spinner />}

            {!isLoading && isEndOfList && (
                <NoItemsCard isEmpty={records?.length === 0} />
            )}
        </div>
    );
};

export default NotificationsPage;
