import React, { useState } from "react";
import { constants, translations } from "../../base.js";
import { NewsApi } from "../../api/newsApi.js";

const NewsForm = ({ formSubmitted }) => {
    const [inputs, setInputs] = useState({ content: "", title: "" });
    const [errorMessage, setError] = useState("");
    const [isFormVisible, setIsFormVisible] = useState(false);

    const { addNews } = NewsApi;

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const checkTitle = () => {
        return inputs.title.length >= constants.VALIDATION.MIN_CONTENT_SIZE;
    };

    const checkContent = () => {
        return inputs.content.length >= constants.VALIDATION.MIN_CONTENT_SIZE;
    };

    const resetForm = () => {
        setError("");
        Object.keys(inputs).forEach((key) => {
            setInputs((prevInputs) => ({ ...prevInputs, [key]: "" }));
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!checkTitle()) {
            setError(translations.Errors.TitleTooShort);
            return;
        }

        if (!checkContent()) {
            setError(translations.Errors.ContentTooShort);
            return;
        }

        const result = await addNews(inputs.title, inputs.content);

        if (!result?.error) {
            setIsFormVisible(false);
            formSubmitted(true);
            resetForm();
        } else {
            setError(result.error);
        }
    };

    const handleClose = (e) => {
        resetForm();
        setIsFormVisible(false);
    };

    const showFormHandler = () => {
        setIsFormVisible(true);
    };

    return (
        <>
            {!isFormVisible && (
                <div className="new-news">
                    <button onClick={showFormHandler}>
                        {translations.News.NewNews}
                    </button>
                </div>
            )}
            {isFormVisible && (
                <div className="overlay">
                    <div className="overlay-modal large">
                        <div className="overlay-modal-content">
                            <p className="header">
                                {translations.News.NewNews}
                            </p>
                            <div className="form">
                                <input
                                    type="text"
                                    name="title"
                                    onKeyUp={handleChange}
                                />
                                <textarea
                                    name="content"
                                    className="compact tall"
                                    onKeyUp={handleChange}
                                    autoFocus
                                ></textarea>
                                {errorMessage && (
                                    <div className="error">{errorMessage}</div>
                                )}
                            </div>
                            <div className="controls">
                                <button
                                    className="submit"
                                    onClick={handleFormSubmit}
                                    disabled={!checkTitle() || !checkContent()}
                                >
                                    {translations.Forms.Submit}
                                </button>
                                <button className="close" onClick={handleClose}>
                                    {translations.Forms.Close}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default NewsForm;
