import { useEffect, useState, useContext } from "react";
import { constants, translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { RoomApi } from "../api/roomApi.js";
import Room from "../components/room/Room.jsx";
import RoomForm from "../components/room/RoomForm.jsx";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";
import DeleteRoomModal from "../components/room/DeleteRoomModal.jsx";

const RoomsPage = () => {
    const typePublic = constants.ROOM_TYPE.PUBLIC;
    const typePrivate = constants.ROOM_TYPE.PRIVATE;

    const { currentUser } = useContext(AuthContext);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [records, setRecords] = useState([]);
    const [errorMessage, setError] = useState("");
    const [trigger, setTrigger] = useState(false);
    const [type, setType] = useState(typePrivate);
    const [roomToDelete, setRoomToDelete] = useState(null);

    const { getUserRooms, getPublicRooms, deleteRoom } = RoomApi;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            const userId = currentUser ? currentUser.id : null;

            let result = [];

            if (type === typePrivate) {
                result = await getUserRooms(
                    userId,
                    constants.DEFAULTS.PAGE_SIZE,
                    lastLoadedId
                );
            } else {
                result = await getPublicRooms(
                    constants.DEFAULTS.PAGE_SIZE,
                    lastLoadedId
                );
            }

            if (!result?.error) {
                if (result.length > 0) {
                    setRecords((prevRecords) => [...prevRecords, ...result]);
                }

                // No more records
                setIsEndOfList(result.length < constants.DEFAULTS.PAGE_SIZE);
            } else {
                setError(result.error);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [lastLoadedId, getUserRooms, trigger, currentUser, type]);

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setLastLoadedId(
            records?.length > 0 ? records[records.length - 1].id : 0
        );
    };

    const handleTypeChange = (newType) => {
        if (newType != type) {
            setRecords([]);
            setType(newType);
        }
    };

    const handleFormSubmitted = (success) => {
        if (success) {
            setRecords([]);
            setLastLoadedId(-Math.random());
        }
    };

    // Sets room with specified id for removal
    const handleDeleteRoom = (id) => {
        const selected = records.find((record) => record.id === id);
        setRoomToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setRoomToDelete(null);
    };

    // Marks selected room as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (roomToDelete) {
            const result = await deleteRoom(roomToDelete.id);

            if (!result?.error) {
                const updatedRecords = records.filter(
                    (record) => record.id !== roomToDelete.id
                );

                setRecords(updatedRecords);
            }
        }

        setRoomToDelete(null);
    };

    return (
        <div className="standard-page rooms">
            <h1>{translations.Menu.Rooms}</h1>

            <div className="page-menu">
                <div className="menu-item">
                    <span
                        onClick={() => {
                            handleTypeChange(typePrivate);
                        }}
                        className={type === typePrivate ? "active" : ""}
                    >
                        {translations.Rooms.Privates}
                    </span>
                </div>

                <div className="menu-item">
                    <span
                        onClick={() => {
                            handleTypeChange(typePublic);
                        }}
                        className={type === typePublic ? "active" : ""}
                    >
                        {translations.Rooms.Publics}
                    </span>
                </div>

                <div className="menu-item">
                    {currentUser && (
                        <RoomForm formSubmitted={handleFormSubmitted} />
                    )}
                </div>
            </div>

            {roomToDelete && (
                <DeleteRoomModal
                    room={roomToDelete}
                    closeModal={handleCloseModal}
                    confirmDelete={handleDeleteComfirmed}
                />
            )}

            {errorMessage && <div className="error">{errorMessage}</div>}

            {records &&
                records.length > 0 &&
                records.map((room) => (
                    <Room
                        room={room}
                        deleteRoom={handleDeleteRoom}
                        key={room.id}
                    />
                ))}

            {!isLoading && !isEndOfList && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {isLoading && <Spinner />}

            {!isLoading && isEndOfList && (
                <NoItemsCard isEmpty={records?.length === 0} />
            )}
        </div>
    );
};

export default RoomsPage;
