import React, { useEffect, useState } from "react";
import { translations } from "../base.js";
import { UserApi } from "../api/userApi.js";
import { Link } from "react-router-dom";

const DynamicRatingsWidget = () => {
    const [users, setUsers] = useState(null);
    const { getDynamicRatings } = UserApi;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getDynamicRatings();

            if (!result?.error) {
                setUsers(result);
            }
        };

        fetchData();
    }, [getDynamicRatings]);

    return (
        users &&
        users.length > 0 && (
            <div className="widget-container">
                <div className="widget">
                    <div className="widget-content">
                        <div className="heading">
                            {translations.Widgets.DynamicRatings}
                        </div>
                        <div>
                            <ol>
                                {users.map((user) => (
                                    <li className="top-users" key={user.id}>
                                        <Link
                                            to={`/user/${user.id}`}
                                            target="_blank"
                                        >
                                            {user.nick}
                                        </Link>{" "}
                                        ({user.total})
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default DynamicRatingsWidget;
