import { useState, useRef, useEffect, useCallback } from "react";
import { UserApi } from "../../api/userApi.js";
import { translations } from "../../base.js";

const UserAutocomplete = ({ handleTriggerParentComponent, placeholder }) => {
    const [inputValue, setInputValue] = useState("");
    const [users, setUsers] = useState([]);
    const containerRef = useRef(null);

    const handleReset = useCallback(() => {
        setInputValue("");
        setUsers([]);
    }, []);

    // Add event listener to register a click outside of the autocomplete text field
    useEffect(() => {
        // If clicked anywhere outside of the autocomplete text field - reset the field and the autocomplete results
        const handleClickOutside = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                handleReset();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleReset]);

    const { getUsersByKeyword } = UserApi;

    const handleKeyUp = async (event) => {
        setUsers([]);
        if (inputValue.length >= 2) {
            const result = await getUsersByKeyword(inputValue);

            if (!result.error) {
                setUsers(result);
            }
        }
    };

    const handleItemSelected = (user) => {
        handleReset();
        handleTriggerParentComponent({ id: user.id, nick: user.nick });
    };

    return (
        <div className="autocomplete-container" ref={containerRef}>
            <input
                type="text"
                className="narrow"
                onKeyUp={handleKeyUp}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                placeholder={placeholder}
            />
            {users && users.length > 0 && (
                <ul>
                    {users.map((item) => (
                        <li
                            key={item.id}
                            onClick={() => handleItemSelected(item)}
                        >
                            {item.nick}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default UserAutocomplete;
