import { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { translations } from "../base.js";
import { format } from "date-fns";
import { AuthContext } from "../context/authContext.js";
import { UserApi } from "../api/userApi.js";
import { getBadgeClass, getBadgeTitle } from "../utils/badges.js";
import Spinner from "../components/common/Spinner.jsx";

const UserPage = () => {
    const [errorMessage, setError] = useState("");
    const { id } = useParams();
    const { currentUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    const [badges, setBadges] = useState([]);

    const [user, setUser] = useState({
        nick: "",
        rating: "",
        location: "",
        info: "",
        registered: "",
        activities: [],
    });

    const { getUserInfo } = UserApi;

    const userId = id ? id : currentUser ? currentUser.id : null;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await getUserInfo(userId);

            setIsLoading(false);

            if (!result?.error) {
                setUser(result);
                if (result.badges?.length > 0) {
                    setBadges(result.badges);
                }
            } else {
                setError(result.error);
            }
        };

        fetchData();
    }, [userId, getUserInfo, currentUser]);

    return (
        <div className="standard-page">
            {!isLoading && !errorMessage && user && (
                <div className="user">
                    <h1>{user.nick}</h1>
                    <div className="row">
                        <b>{translations.Common.Rating}:</b>{" "}
                        <span className="rating">{user.rating}</span>
                    </div>
                    {user.location && (
                        <div>
                            <b>{translations.User.Location}:</b> {user.location}
                        </div>
                    )}
                    {user.info && (
                        <div className="row">
                            <b>{translations.User.Info}:</b> {user.info}
                        </div>
                    )}
                    <div className="row">
                        {badges.length > 0 && (
                            <strong>{translations.Menu.Badges}: </strong>
                        )}
                        {badges.length > 0 && (
                            <div className="badge-container">
                                {badges.map((badge) => (
                                    <div
                                        className={
                                            "badge " + getBadgeClass(badge.id)
                                        }
                                        key={badge.id}
                                    >
                                        <div className="icon">{badge.cnt}x</div>
                                        <div className="title">
                                            {getBadgeTitle(badge.id)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <b>{translations.User.IWrote}:</b>{" "}
                        <Link to={`/userprs/${userId}`}>
                            {translations.Menu.Parents} (
                            {user.activities.parentCnt})
                        </Link>{" "}
                        |{" "}
                        <Link to={`/userchs/${userId}`}>
                            {translations.Menu.Children} (
                            {user.activities.childCnt})
                        </Link>
                    </div>
                    {user.registered && (
                        <div className="row">
                            <b>{translations.User.Registered}:</b>{" "}
                            {format(new Date(user.registered), "dd.MM.yyyy")}{" "}
                        </div>
                    )}
                    <div className="row">
                        <b>{translations.User.ContactUser}:</b>{" "}
                        <Link to={`/messages/${userId}`}>
                            {translations.User.SendMessage}
                        </Link>
                    </div>
                </div>
            )}

            {errorMessage && (
                <p className="error-message-block">{errorMessage}</p>
            )}

            {isLoading && <Spinner />}
        </div>
    );
};

export default UserPage;
