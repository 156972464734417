import React, { useEffect, useState } from "react";
import RoomInfo from "../../widgets/RoomInfo.jsx";
import RoomUsers from "../../widgets/RoomUsers.jsx";
import RoomLinks from "../../widgets/RoomLinks.jsx";
import { RoomApi } from "../../api/roomApi.js";

const RoomWidgets = ({ roomId }) => {
    const [room, setRoom] = useState(null);
    const { getRoomInfo } = RoomApi;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getRoomInfo(roomId);

            if (result && !result.error) {
                setRoom(result);
            }
        };

        fetchData();
    }, [getRoomInfo]);

    return (
        <div className="sidebar-widgets">
            <RoomInfo room={room} />
            <RoomLinks room={room} />
            <RoomUsers room={room} />
        </div>
    );
};

export default RoomWidgets;
