import React from "react";
import { Helmet } from "react-helmet";

// Google tag (gtag.js)
const GoogleAnalytics = ({ gaId }) => (
    <Helmet>
        <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
        ></script>
        <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag("js", new Date());

            gtag("config", "${gaId}");
            `}
        </script>
    </Helmet>
);

export default GoogleAnalytics;
