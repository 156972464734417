import React, { useContext } from "react";
import { getTimeAgo } from "../../utils/dates.js";
import { AuthContext } from "../../context/authContext.js";
import DeleteIcon from "../../images/delete.svg";

const NewsComment = ({ comment, deleteComment }) => {
    const { currentUser } = useContext(AuthContext);

    const handleDelete = async (e) => {
        deleteComment(comment.id);
    };

    // Show edit/delete buttons if:
    // - User is the author
    // - User is super admin
    const showActionButtons = () => {
        return currentUser?.id === comment.userId || currentUser?.isSuperAdmin;
    };

    return (
        <div className="comment">
            <div className="info-container">
                <div className="info">
                    <div className="user">{comment.nick}</div>
                    <div className="date">{getTimeAgo(comment.created)}</div>
                </div>
                {showActionButtons() && (
                    <div className="links">
                        <img
                            src={DeleteIcon}
                            alt="Delete"
                            onClick={handleDelete}
                        />
                    </div>
                )}
            </div>
            <div className="content">
                {comment.comment.split("\n").map((item, key) => {
                    return <p key={key}>{item}</p>;
                })}
            </div>
        </div>
    );
};

export default NewsComment;
