import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { UserApi } from "../api/userApi.js";

const CurrentUserWidget = () => {
    const { currentUser } = useContext(AuthContext);

    const [user, setUser] = useState(null);
    const { getUserInfo } = UserApi;

    useEffect(() => {
        const fetchData = async () => {
            if (currentUser) {
                const result = await getUserInfo(currentUser.id);

                if (result && !result?.error) {
                    setUser(result);
                }
            }
        };

        fetchData();
    }, [getUserInfo, currentUser]);

    return (
        currentUser && (
            <div className="widget-container">
                <div className="widget info">
                    <div className="nick">
                        <Link to={`/user/${currentUser.id}`}>
                            {currentUser.nick}
                        </Link>
                    </div>
                    <div className="menu">
                        <div className="menu-item">
                            <Link to="/my">
                                {translations.Menu.MyChildren}
                                {user &&
                                    user.activities &&
                                    ` (${user.activities.childCnt})`}
                            </Link>
                        </div>

                        <div className="menu-item">
                            <Link to="/mysetups">
                                {translations.Menu.MyParents}
                                {user &&
                                    user.activities &&
                                    ` (${user.activities.parentCnt})`}
                            </Link>
                        </div>

                        <div className="menu-item">
                            <Link to="/favorites">
                                {translations.Menu.Favorites}
                            </Link>
                        </div>
                        <div className="menu-item">
                            <Link to="/messages">
                                {translations.Menu.Messages}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default CurrentUserWidget;
