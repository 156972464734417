import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { constants, translations } from "../base.js";
import { SearchApi } from "../api/searchApi.js";
import Spinner from "../components/common/Spinner.jsx";

const SearchPage = () => {
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [searchResults, setSearchResults] = useState([]);
    const [inputs, setInputs] = useState({ keyword: "" });
    const [keyword, setKeyword] = useState("");
    const [errorMessage, setError] = useState("");
    const [trigger, setTrigger] = useState(false);
    const [isShowButton, setIsShowButton] = useState(false);

    const { getSearchResults } = SearchApi;

    useEffect(() => {
        const fetchData = async () => {
            if (keyword) {
                setIsLoading(true);
                setIsUnavailable(false);

                const result = await getSearchResults(
                    keyword,
                    constants.DEFAULTS.PAGE_SIZE,
                    lastLoadedId
                );

                if (!result?.error && result.length > 0) {
                    setSearchResults((prevRecords) => [
                        ...prevRecords,
                        ...result,
                    ]);
                } else if (result?.error) {
                    setError(result.error);
                }

                setIsLoading(false);
            }
        };

        fetchData();
    }, [lastLoadedId, getSearchResults, trigger, inputs.keyword]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setSearchResults([]);
        setLastLoadedId(0);
        setIsShowButton(true);

        if (!checkContent()) {
            setError(translations.Errors.ContentTooShort);
            return;
        } else {
            setKeyword(inputs.keyword);
        }

        handleLoadMore();
    };

    const checkContent = () => {
        return inputs.keyword.length >= constants.VALIDATION.MIN_CONTENT_SIZE;
    };

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setLastLoadedId(
            searchResults?.length > 0
                ? searchResults[searchResults.length - 1].id
                : 0
        );
    };

    return (
        <div className="standard-page">
            <h1>{translations.Menu.Search}</h1>

            <form className="horizontal-form">
                <input
                    name="keyword"
                    value={inputs.keyword}
                    onChange={handleChange}
                    autoFocus
                />
                <div className="actions">
                    <button
                        className="submit"
                        onClick={handleFormSubmit}
                        disabled={!checkContent()}
                    >
                        {translations.Forms.Find}
                    </button>
                </div>
            </form>

            {errorMessage && <div className="error">{errorMessage}</div>}

            <div className="search-results">
                {searchResults.length > 0 && <h2>Вот, что мы нашли</h2>}
                {searchResults.map((item) => (
                    <div className="search-result" key={item.id}>
                        {!item.parentContent && (
                            <div className="item">
                                {item.content}{" "}
                                <Link
                                    to={`/parent/${item.id}`}
                                    target="_blank"
                                    className="link"
                                >
                                    [{translations.Parents.Parent}]
                                </Link>
                            </div>
                        )}
                        {item.parentContent && (
                            <>
                                {`${item.parentContent} ${item.content}`}{" "}
                                <Link
                                    to={`/child/${item.id}`}
                                    target="_blank"
                                    className="link"
                                >
                                    [{translations.Children.Child}]
                                </Link>
                            </>
                        )}
                    </div>
                ))}
            </div>

            {isLoading && <Spinner />}

            {!isLoading && isShowButton && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}
        </div>
    );
};

export default SearchPage;
