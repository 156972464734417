import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { translations } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";

const ProfileMenu = ({
    className,
    updateNotificationCount,
    notificationCount = 0,
}) => {
    const location = useLocation();
    const { currentUser, logout } = useContext(AuthContext);

    return (
        <div className={className}>
            <div className="left">
                <ul>
                    <li className="nick">
                        <Link
                            to={"/user/" + currentUser?.id}
                            className={
                                location.pathname === "/user/" + currentUser?.id
                                    ? "active"
                                    : ""
                            }
                        >
                            {currentUser?.nick}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/my"
                            className={
                                location.pathname === "/my" ? "active" : ""
                            }
                        >
                            {translations.Menu.MyChildren}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/mysetups"
                            className={
                                location.pathname === "/mysetups"
                                    ? "active"
                                    : ""
                            }
                        >
                            {translations.Menu.MyParents}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/favorites"
                            className={
                                location.pathname === "/favorites"
                                    ? "active"
                                    : ""
                            }
                        >
                            {translations.Menu.Favorites}
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="right">
                <ul>
                    <li>
                        <Link
                            className={
                                location.pathname === "/notifications"
                                    ? "active"
                                    : ""
                            }
                            to="/notifications"
                            onClick={updateNotificationCount}
                        >
                            <span
                                className={
                                    notificationCount > 0
                                        ? "notifications-active"
                                        : ""
                                }
                            >
                                {translations.Menu.Notifications} (
                                {notificationCount})
                            </span>
                        </Link>
                    </li>

                    <li>
                        <Link
                            to="/messages"
                            className={
                                location.pathname === "/messages"
                                    ? "active"
                                    : ""
                            }
                        >
                            {translations.Menu.Messages}
                        </Link>
                    </li>

                    <li>
                        <Link
                            to="/changepassword"
                            className={
                                location.pathname === "/changepassword"
                                    ? "active"
                                    : ""
                            }
                        >
                            {translations.Auth.ChangePassword}
                        </Link>
                    </li>
                    <li>
                        <span className="logout" onClick={logout}>
                            {translations.Auth.Logout}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ProfileMenu;
