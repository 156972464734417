import { constants } from "../base.js";
import AboutPageContent_ru from "./content/AboutPageContent_ru.jsx";
import AboutPageContent_en from "./content/AboutPageContent_en.jsx";

const AboutPage = ({ title, lang }) => {
    return (
        <div className="standard-page static">
            <h1>{title}</h1>
            {lang === constants.LANGUAGE.RUSSIAN ? (
                <AboutPageContent_ru />
            ) : (
                <AboutPageContent_en />
            )}
        </div>
    );
};

export default AboutPage;
