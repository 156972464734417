import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";
import { NOVEL_PART_TYPE } from "../constants.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const NovelApi = {
    /**
     * Adds a part of a novel
     *
     * @param {string} content
     * @param {number} parentId
     * @param {number} userId
     */
    addNovelPart: async (
        content,
        userId,
        partType = NOVEL_PART_TYPE.BEGINNING,
        parentId = null
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/novels/add`;
            const params = {
                content: content,
                partType: partType,
                parentId: parentId,
                userId: userId,
            };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "PART_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Gets the part of a novel
     *
     * @param {number} id
     */
    getNovelPart: async (id, userId = null) => {
        try {
            const endpointUrl = `${SERVICE_URL}/novels/part/${id}/${userId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "CHILD_NOT_FOUND":
                    error = translations.Children.ChildNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return {};
    },
};
