import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const CommentApi = {
    /**
     * Gets comments
     *
     * @param {number} lastLoadedId
     * @param {number} userId current user's id
     * @param {number} count
     */
    getItemComments: async (
        commentedItemId,
        count = constants.DEFAULTS.PAGE_SIZE,
        lastLoadedId = 0
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/comments/list/${commentedItemId}/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Deletes the comment
     *
     * @param {number} id
     */
    deleteComment: async (id) => {
        try {
            const endpointUrl = `${SERVICE_URL}/comments/delete`;
            const params = { id: id };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Adds a comment
     *
     * @param {string} content
     * @param {number} itemId
     * @param {number} userId
     */
    addComment: async (content, itemId, userId) => {
        try {
            const endpointUrl = `${SERVICE_URL}/comments/add`;
            const params = { content: content, itemId: itemId, userId: userId };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "COMMENT_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Gets children
     *
     * @param {number} lastLoadedId
     * @param {number} userId current user's id
     * @param {number} count
     */
    getNewsComments: async (
        commentedNewsId,
        count = constants.DEFAULTS.PAGE_SIZE,
        lastLoadedId
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/newscomments/list/${commentedNewsId}/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Deletes the news comment
     *
     * @param {number} id
     */
    deleteNewsComment: async (id) => {
        try {
            const endpointUrl = `${SERVICE_URL}/newscomments/delete`;
            const params = { id: id };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Adds a news comment
     *
     * @param {string} content
     * @param {number} newsId
     * @param {number} userId
     */
    addNewsComment: async (content, newsId, userId) => {
        try {
            const endpointUrl = `${SERVICE_URL}/newscomments/add`;
            const params = { content: content, newsId: newsId, userId: userId };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "COMMENT_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },
};
