import React from "react";
import { getTimeAgo } from "../../utils/dates.js";

const EventNotification = ({ eventNotification }) => {
    return (
        <div
            className={
                eventNotification.isNew ? "notification new" : "notification"
            }
        >
            <div
                className="content"
                dangerouslySetInnerHTML={{
                    __html: eventNotification.content,
                }}
            />
            <div className="date">{getTimeAgo(eventNotification.created)}</div>
        </div>
    );
};

export default EventNotification;
