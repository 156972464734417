import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";

const LoginPage = () => {
    const [inputs, setInputs] = useState({ email: "", password: "" });
    const [errorMessage, setError] = useState("");
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await login(inputs.email, inputs.password);

            navigate("/");
        } catch (err) {
            const errorMessage = err.response?.data?.error;

            switch (errorMessage) {
                case "USER_NOT_FOUND":
                    setError(translations.Errors.UserNotFound);
                    break;
                case "USER_NOT_ACTIVE":
                    setError(translations.Errors.LoginUserNotActive);
                    break;
                case "WRONG_EMAIL_OR_PASSWORD":
                    setError(translations.Errors.PasswordIncorrect);
                    break;
                default:
                    setError(translations.Errors.GeneralError);
                    break;
            }
        }
    };

    useEffect(() => {
        setIsButtonDisabled(!inputs.email || !inputs.password);
    }, [inputs.email, inputs.password]);

    return (
        <div className="auth">
            <h1>{translations.Auth.Enter}</h1>
            <form>
                <input
                    required
                    type="text"
                    placeholder={translations.Auth.Email}
                    name="email"
                    onChange={handleChange}
                />
                <input
                    required
                    type="password"
                    placeholder={translations.Auth.Password}
                    name="password"
                    onChange={handleChange}
                />
                <button disabled={isButtonDisabled} onClick={handleSubmit}>
                    {translations.Forms.Submit}
                </button>
                {errorMessage && <p className="error">{errorMessage}</p>}
            </form>
            <p>
                <span>
                    <Link to="/resetpassword">
                        {translations.Auth.ForgotPassword}
                    </Link>{" "}
                    |{" "}
                    <Link to="/register">
                        {translations.Auth.YouCanRegister}
                    </Link>
                </span>
            </p>
        </div>
    );
};

export default LoginPage;
