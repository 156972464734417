import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { constants, translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { ChildApi } from "../api/childApi.js";
import { RoomApi } from "../api/roomApi.js";
import { checkUserAccess } from "../utils/rooms.js";
import RoomWidgets from "../components/common/RoomWidgets.jsx";
import Child from "../components/child/Child.jsx";
import Spinner from "../components/common/Spinner.jsx";
import ConfirmDeleteChild from "../components/child/ConfirmDeleteChild.jsx";
import ConfirmModal from "../components/common/ConfirmModal.jsx";

const ChildPage = () => {
    const { id, type } = useParams();
    const [room, setRoom] = useState(null);
    const [showSidebar, setShowSidebar] = useState(false);
    const [isAccessAllowed, setIsAccessAllowed] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [errorMessage, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [child, setChild] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const [childToDelete, setChildToDelete] = useState(null);
    const navigate = useNavigate();

    const { getChild, deleteChild } = ChildApi;
    const { getRoomInfo } = RoomApi;

    useEffect(() => {
        const fetchData = async () => {
            if (child) {
                if (child.roomId) {
                    setIsLoading(true);

                    const result = await getRoomInfo(child.roomId);

                    setIsLoading(false);

                    if (result && !result.error) {
                        setRoom(result);
                        setShowSidebar(true);

                        const canAccess = checkUserAccess(
                            currentUser ? currentUser.id : null,
                            result
                        );

                        if (canAccess) {
                            setIsAccessAllowed(true);
                        } else {
                            setDisplayErrorMessage(true);
                        }
                    }
                } else {
                    setIsAccessAllowed(true);
                }
            }
        };

        fetchData();
    }, [getRoomInfo, checkUserAccess, currentUser, child]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await getChild(
                id,
                currentUser ? currentUser.id : null
            );

            setIsLoading(false);

            if (!result?.error) {
                setChild(result);
            } else if (result?.error) {
                setError(result.error);
            } else {
                setIsUnavailable(true);
            }
        };

        fetchData();
    }, [id, getChild, currentUser]);

    // Sets child with specified id for removal
    const handleDeleteChild = (id) => {
        setChildToDelete(child);
    };

    // Closes modal
    const handleCloseModal = () => {
        setChildToDelete(null);
    };

    // Marks selected child as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (childToDelete) {
            await deleteChild(childToDelete.id);
            navigate("/");
        }

        setChildToDelete(null);
    };

    return (
        <div className="room">
            <Helmet>
                <title>
                    {isAccessAllowed == true && child != null
                        ? `${child.parentContent} ${child.content}`
                        : translations.Menu.Child}
                </title>
            </Helmet>

            {isAccessAllowed == true && !isLoading && child != null && (
                <div className="two-column-container">
                    <div className="child-page">
                        <h1>{translations.Menu.ParentAndChild}</h1>

                        {childToDelete && (
                            <ConfirmModal
                                contentComponent={
                                    <ConfirmDeleteChild child={childToDelete} />
                                }
                                closeModal={handleCloseModal}
                                confirmDelete={handleDeleteComfirmed}
                            />
                        )}

                        <Child
                            child={child}
                            showComments={
                                type ===
                                constants.ROUTES.CHILD_PAGE_TYPE_WITH_COMMENTS
                            }
                            deleteChild={handleDeleteChild}
                        />
                    </div>
                    {showSidebar && room != null && (
                        <div className="right-sidebar">
                            <RoomWidgets roomId={room.id} />
                        </div>
                    )}
                </div>
            )}

            {errorMessage && (
                <p className="error-message-block">{errorMessage}</p>
            )}

            {isLoading && <Spinner />}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {displayErrorMessage && (
                <div className="error-message-block">
                    {translations.Errors.RoomAccessDenied}
                </div>
            )}
        </div>
    );
};

export default ChildPage;
