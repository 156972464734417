import React, { useState, useContext } from "react";
import { constants, translations } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";
import { RoomApi } from "../../api/roomApi.js";
import UserAutocomplete from "../../components/controls/UserAutocomplete.jsx";

const RoomEditForm = ({ room, formSubmitted, formClosed }) => {
    const [name, setName] = useState(room.name);
    const [description, setDescription] = useState(room.description);
    const [isPrivate, setIsPrivate] = useState(room.isPublic == 0);

    const [participants, setParticipants] = useState(room.users ?? []);
    const [errorMessage, setError] = useState("");
    const { currentUser } = useContext(AuthContext);

    const { editRoom } = RoomApi;

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleIsPrivateChange = (e) => {
        const privateRoom = e.target.checked;

        if (!privateRoom) {
            setParticipants([]);
        }

        setIsPrivate(privateRoom);
    };

    const resetForm = () => {
        setName("");
        setDescription("");
        setParticipants([]);
    };

    const checkName = () => {
        return name.length >= constants.VALIDATION.MIN_CONTENT_SIZE;
    };

    const checkDescription = () => {
        return name.length <= constants.VALIDATION.MAX_CONTENT_SIZE;
    };

    const handleFormSubmit = async (e) => {
        e.target.disabled = true;
        e.preventDefault();

        if (!checkName()) {
            setError(translations.Errors.NameTooShort);
            return;
        }

        if (!checkDescription()) {
            setError(translations.Errors.DescriptionTooLong);
            return;
        }

        const userIds = isPrivate ? participants.map((p) => p.id) : [];

        if (isPrivate && !userIds.includes(currentUser.id)) {
            userIds.push(currentUser.id);
        }

        const result = await editRoom(
            room.id,
            room.ownerId,
            name,
            description,
            !isPrivate,
            userIds
        );

        if (!result?.error) {
            formSubmitted(true, name, description, !isPrivate, participants);
            resetForm();
        } else {
            setError(result.error);
        }
    };

    const handleClose = (e) => {
        e.preventDefault();
        formClosed();
    };

    const handleParticipantAdded = (newParticipant) => {
        if (
            participants.find((item) => item.id == newParticipant.id) ===
            undefined
        ) {
            setParticipants((prev) => [
                ...prev,
                {
                    id: newParticipant.id,
                    nick: newParticipant.nick,
                    isAdmin: room.ownerId == newParticipant.id ? 1 : 0,
                },
            ]);
        }
    };

    const handleParticipantRemoved = (id) => {
        const updatedParticipants = participants.filter(
            (item) => item.id !== id
        );
        setParticipants(updatedParticipants);
    };

    return (
        <>
            <form className="vertical-form">
                <div>
                    <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleNameChange}
                    />
                </div>
                <div>
                    <textarea
                        name="description"
                        value={description}
                        onChange={handleDescriptionChange}
                        autoFocus
                    ></textarea>
                </div>
                <div className="checkbox-container">
                    <input
                        type="checkbox"
                        name="isPrivate"
                        checked={isPrivate}
                        onChange={handleIsPrivateChange}
                    />
                    <label>{translations.Rooms.Private}</label>
                </div>
                {isPrivate && (
                    <div>
                        <UserAutocomplete
                            handleTriggerParentComponent={
                                handleParticipantAdded
                            }
                            placeholder={translations.Rooms.AddParticipants}
                        />
                    </div>
                )}

                {isPrivate && participants.length > 0 && (
                    <div className="autocomplete-selected-items">
                        {participants.map((user) => (
                            <div
                                className="item"
                                key={user.id}
                                onClick={() => {
                                    handleParticipantRemoved(user.id);
                                }}
                            >
                                {user.nick}
                            </div>
                        ))}
                    </div>
                )}
                <div className="actions">
                    <button
                        className="submit"
                        onClick={handleFormSubmit}
                        disabled={!checkName() || !checkDescription()}
                    >
                        {translations.Forms.Save}
                    </button>
                    <button className="close" onClick={handleClose}>
                        {translations.Forms.Close}
                    </button>
                </div>
                {errorMessage && <div className="error">{errorMessage}</div>}
            </form>
        </>
    );
};

export default RoomEditForm;
