import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { translations, constants } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { ChildApi } from "../api/childApi.js";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";
import Child from "../components/child/Child.jsx";
import ConfirmDeleteChild from "../components/child/ConfirmDeleteChild.jsx";
import ConfirmModal from "../components/common/ConfirmModal.jsx";

const TopChildrenPage = () => {
    const [records, setChildren] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [childToDelete, setChildToDelete] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const [startWith, setStartWith] = useState(0);
    const [trigger, setTrigger] = useState(false);
    const { type } = useParams();
    const [dynamicType, setDynamicType] = useState(
        type ? parseInt(type, 10) : constants.LIST_TYPE.RECENT
    );

    const showControls = dynamicType !== constants.LIST_TYPE.RECENT;

    const { deleteChild, getTopChildren } = ChildApi;

    useEffect(() => {
        setChildren([]);
    }, [dynamicType]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            const userId = currentUser ? currentUser.id : null;
            const result = await getTopChildren(
                dynamicType || constants.LIST_TYPE.ALL,
                userId,
                constants.DEFAULTS.PAGE_SIZE,
                startWith
            );

            if (!result.error) {
                if (result.length > 0) {
                    setChildren((prevRecords) => [...prevRecords, ...result]);
                }

                if (result.length < constants.DEFAULTS.PAGE_SIZE) {
                    // No more records
                    setIsEndOfList(true);
                }
            } else {
                setIsUnavailable(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [startWith, currentUser, trigger, getTopChildren, dynamicType]);

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setStartWith(records?.length);
    };

    const handleTypeChange = (newType) => {
        if (newType === dynamicType) {
            setTrigger((prev) => !prev);
        }

        setDynamicType(newType);
    };

    // Sets child with specified id for removal
    const handleDeleteChild = (id) => {
        const selected = records.find((record) => record.id === id);
        setChildToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setChildToDelete(null);
    };

    // Marks selected child as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (childToDelete) {
            const result = await deleteChild(childToDelete.id);

            if (!result.error) {
                const updatedRecords = records.filter(
                    (record) => record.id !== childToDelete.id
                );
                setChildren(updatedRecords);
            }
        }

        setChildToDelete(null);
    };

    return (
        <div className="children-content">
            <h1>{translations.Menu.Top}</h1>

            <div className="page-menu">
                <div className="menu-item">
                    <span
                        className={
                            dynamicType === constants.LIST_TYPE.RECENT
                                ? "active"
                                : ""
                        }
                        onClick={() => {
                            if (dynamicType !== constants.LIST_TYPE.RECENT)
                                handleTypeChange(constants.LIST_TYPE.RECENT);
                        }}
                    >
                        {translations.Menu.TopRecent}
                    </span>
                </div>
                <div className="menu-item">
                    <span
                        className={
                            dynamicType === constants.LIST_TYPE.ALL
                                ? "active"
                                : ""
                        }
                        onClick={() => {
                            if (dynamicType !== constants.LIST_TYPE.ALL)
                                handleTypeChange(constants.LIST_TYPE.ALL);
                        }}
                    >
                        {translations.Menu.TopAll}
                    </span>
                </div>
            </div>

            {childToDelete && (
                <ConfirmModal
                    contentComponent={
                        <ConfirmDeleteChild child={childToDelete} />
                    }
                    closeModal={handleCloseModal}
                    confirmDelete={handleDeleteComfirmed}
                />
            )}

            {records.map((child) => (
                <div key={child.id}>
                    <Child child={child} deleteChild={handleDeleteChild} />
                </div>
            ))}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {showControls && !isLoading && !isEndOfList && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {isLoading && <Spinner />}

            {!isLoading && isEndOfList && (
                <NoItemsCard isEmpty={records?.length === 0} />
            )}
        </div>
    );
};

export default TopChildrenPage;
