import Loader from "../../images/loader-round.svg";

const SpinnerRound = () => {
    return (
        <div className="loader round">
            <img src={Loader} alt="Loader" />
        </div>
    );
};

export default SpinnerRound;
