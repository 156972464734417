import React, { useState } from "react";
import { constants, translations } from "../../base.js";
import { ChildApi } from "../../api/childApi.js";

const ChildEditForm = ({ child, formSubmitted, formClosed }) => {
    const [inputs, setInputs] = useState({ content: child.content });
    const [errorMessage, setError] = useState("");

    const { editChild } = ChildApi;

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const resetForm = () => {
        Object.keys(inputs).forEach((key) => {
            setInputs((prevInputs) => ({ ...prevInputs, [key]: "" }));
        });
    };

    const checkContent = () => {
        return inputs.content.length >= constants.VALIDATION.MIN_CONTENT_SIZE;
    };

    const handleFormSubmit = async (e) => {
        e.target.disabled = true;
        e.preventDefault();

        if (!checkContent()) {
            setError(translations.Errors.ContentTooShort);
            return;
        }

        const result = await editChild(child.id, inputs.content);

        if (!result?.error) {
            formSubmitted(true, inputs.content);
            resetForm();
        } else {
            setError(result.error);
        }
    };

    const handleClose = (e) => {
        e.preventDefault();
        formClosed();
    };

    return (
        <>
            <form className="horizontal-form">
                <textarea
                    name="content"
                    value={inputs.content}
                    onChange={handleChange}
                    autoFocus
                ></textarea>
                <div className="actions">
                    <button
                        className="submit"
                        onClick={handleFormSubmit}
                        disabled={!checkContent()}
                    >
                        {translations.Forms.Save}
                    </button>
                    <button className="close" onClick={handleClose}>
                        {translations.Forms.Close}
                    </button>
                </div>
            </form>
            {errorMessage && <div className="error">{errorMessage}</div>}
        </>
    );
};

export default ChildEditForm;
