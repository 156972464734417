import React from "react";
import { Link } from "react-router-dom";
import { translations } from "../../base.js";
import SendIcon from "../../images/send.svg";

const ChildWidget = ({ child }) => {
    return (
        child && (
            <div className="flex-container">
                <div className="main">
                    <div className="content">
                        {child.parentContent?.split("\n").map((item, key) => {
                            return <p key={key}>{item}</p>;
                        })}

                        {child.content.split("\n").map((item, key) => {
                            return <p key={key}>{item}</p>;
                        })}
                    </div>
                    <div className="actions">
                        <div className="item">
                            <Link to={`/child/${child.id}`} target="_blank">
                                <img
                                    src={SendIcon}
                                    alt={translations.Icons.Share}
                                    title={translations.Icons.Share}
                                    className="share-link"
                                />
                            </Link>
                        </div>
                        <div className="user">
                            <Link
                                to={`/user/${child.userId}`}
                                target="_blank"
                                className="user-link"
                            >
                                {child.nick}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default ChildWidget;
