import { translations } from "../../base.js";

const DeleteParentModal = ({ parent, closeModal, confirmDelete }) => {
    return (
        <div className="overlay">
            <div className="overlay-modal">
                <div className="overlay-modal-content">
                    <p className="header">
                        {translations.Forms.AreYouSureDelete}:
                    </p>
                    <div className="content">
                        <div className="parent-content">
                            {parent.content.split("\n").map((item, key) => {
                                return <p key={key}>{item}</p>;
                            })}
                        </div>
                    </div>
                    <div className="controls">
                        <button className="submit" onClick={confirmDelete}>
                            {translations.Forms.Delete}
                        </button>
                        <button className="close" onClick={closeModal}>
                            {translations.Forms.Cancel}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteParentModal;
