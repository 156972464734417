import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext.js";
import { constants, translations } from "../base.js";

const ChangePasswordPage = () => {
    const { currentUser } = useContext(AuthContext);
    const [inputs, setInputs] = useState({
        password: "",
        newPassword: "",
    });

    const [errorMessage, setError] = useState("");
    const [successMessage, setSuccess] = useState("");

    const { changePassword } = useContext(AuthContext);

    const checkContent = () => {
        return (
            inputs.password?.length < constants.VALIDATION.MIN_CONTENT_SIZE ||
            inputs.newPassword?.length <
                constants.VALIDATION.MIN_PASSWORD_SIZE ||
            inputs.newPassword?.length > constants.VALIDATION.MAX_PASSWORD_SIZE
        );
    };

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");

        try {
            if (currentUser) {
                await changePassword(
                    currentUser.email,
                    inputs.password,
                    inputs.newPassword
                );

                setSuccess(translations.Auth.ResetPasswordConfirmed);
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error;

            switch (errorMessage) {
                case "WRONG_CURRENT_PASSWORD":
                    setError(translations.Errors.CurrentPasswordIncorrect);
                    break;
                case "USER_NOT_FOUND":
                    setError(translations.Errors.UserNotFound);
                    break;
                default:
                    setError(translations.Errors.GeneralError);
                    break;
            }
        }
    };

    return (
        <div className="auth">
            <h1>{translations.Auth.ChangePassword}</h1>

            {!currentUser && !successMessage && (
                <p className="warning">
                    {translations.Auth.LoginToChangePassword}
                </p>
            )}

            {currentUser && !successMessage && (
                <p className="warning">
                    {translations.Auth.PasswordRules.replace(
                        "{1}",
                        constants.VALIDATION.MIN_PASSWORD_SIZE
                    ).replace("{2}", constants.VALIDATION.MAX_PASSWORD_SIZE)}
                </p>
            )}

            {(successMessage || !currentUser) && (
                <p className="success">
                    {successMessage}{" "}
                    <Link to="/login">{translations.Auth.Login}</Link>.
                </p>
            )}

            {!successMessage && currentUser && (
                <form>
                    <input
                        required
                        type="password"
                        placeholder={translations.Auth.CurrentPassword}
                        name="password"
                        onChange={handleChange}
                        autoComplete="new-password"
                    />
                    <input
                        required
                        type="password"
                        placeholder={translations.Auth.NewPassword}
                        name="newPassword"
                        onChange={handleChange}
                        autoComplete="new-password"
                        maxLength={constants.VALIDATION.MAX_PASSWORD_SIZE}
                    />
                    <button onClick={handleSubmit} disabled={checkContent()}>
                        {translations.Forms.Submit}
                    </button>
                    {errorMessage && <p className="error">{errorMessage}</p>}
                </form>
            )}
        </div>
    );
};

export default ChangePasswordPage;
