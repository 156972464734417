import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { translations } from "../base.js";
import { UserApi } from "../api/userApi.js";
import MedalGold from "../images/medal-gold.svg";
import MedalSilver from "../images/medal-silver.svg";
import MedalBronze from "../images/medal-bronze.svg";

const UserOfTheMonthWidget = () => {
    const [users, setUsers] = useState(null);
    const { getDynamicRatings } = UserApi;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getDynamicRatings(false);

            if (result && result.length > 0) {
                setUsers(result);
            }
        };

        fetchData();
    }, [getDynamicRatings]);

    return (
        users &&
        users.length > 0 && (
            <div className="widget-container">
                <div className="widget center-content">
                    <div className="widget-content">
                        <div className="heading">
                            {translations.Widgets.UserOfTheMonth}
                        </div>
                        <div className="content-large">
                            <div className="podium">
                                {users[0] && (
                                    <div className="first">
                                        <Link to={`/user/${users[0].id}`}>
                                            {users[0].nick}
                                        </Link>

                                        <div className="image-container">
                                            <img
                                                src={MedalGold}
                                                alt={
                                                    translations.Icons.MedalGold
                                                }
                                            />
                                            <div className="number">1</div>
                                        </div>
                                    </div>
                                )}
                                {users[1] && (
                                    <div className="second">
                                        <Link to={`/user/${users[1].id}`}>
                                            {users[1].nick}
                                        </Link>
                                        <div className="image-container">
                                            <img
                                                src={MedalSilver}
                                                alt={
                                                    translations.Icons
                                                        .MedalSilver
                                                }
                                            />
                                            <div className="number">2</div>
                                        </div>
                                    </div>
                                )}
                                {users[2] && (
                                    <div className="third">
                                        <Link to={`/user/${users[2].id}`}>
                                            {users[2].nick}
                                        </Link>
                                        <div className="image-container">
                                            <img
                                                src={MedalBronze}
                                                alt={
                                                    translations.Icons
                                                        .MedalBronze
                                                }
                                            />
                                            <div className="number">3</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default UserOfTheMonthWidget;
