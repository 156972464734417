import { translations } from "../../base.js";
import { Link } from "react-router-dom";
import { getBadgeClass, getBadgeTitle } from "../../utils/badges.js";

const UserBadgesCompact = ({ badges }) => {
    return (
        <div className="badges compact">
            {badges && badges.length > 0 && (
                <div className="badge-container compact">
                    {badges.map((badge) => {
                        const badgeTitle = getBadgeTitle(badge.id);
                        return (
                            <Link
                                to="/badges"
                                title={badgeTitle}
                                key={badge.id}
                            >
                                <div
                                    className={
                                        "badge compact " +
                                        getBadgeClass(badge.id)
                                    }
                                >
                                    <div className="icon compact">
                                        {badge.cnt}x
                                    </div>
                                    <div className="title compact">
                                        {badgeTitle
                                            .split(" ")
                                            .map((word) =>
                                                word.charAt(0).toUpperCase()
                                            )
                                            .join("")}
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default UserBadgesCompact;
