const AboutPageContent_ru = () => {
    return (
        <div className="content">
            <p>
                Добро пожаловать на сайт, где задают смешные вопросы и смешно на
                них отвечают!
            </p>
            <p>
                Это почти как КВН-разминка &mdash; вопросы и ответы, но в жюри
                &mdash; все участники.
            </p>
            <p>Шутки, прибаутки &mdash; все это у нас&nbsp;:)</p>
            <h2>Чего тут можно делать</h2>
            <ul>
                <li>
                    <a href="#start">Начало</a>
                </li>
                <li>
                    <a href="#terms">Терминология</a>
                </li>
                <li>
                    <a href="#rating">Общий рейтинг</a>
                </li>
                <li>
                    <a href="#d-rating">Динамический рейтинг (Топ месяца)</a>
                </li>
                <li>
                    <a href="#voting">Голосование</a>
                </li>
                <li>
                    <a href="#comments">Комментирование</a>
                </li>
                <li>
                    <a href="#rooms">Комнатушки</a>
                </li>
                <li>
                    <a href="#grammar">Грамотность</a>
                </li>
                <li>
                    <a href="#rules">Правила</a>
                </li>
            </ul>
            <h2 id="start">Начало</h2>
            <p>
                Тут все просто. Можно просто читать, а можно и поучаствовать.
                Сделайте для этого всего 3 маленьких шажочка:
            </p>
            <ol>
                <li>
                    Пройдите простейшую <a href="/register">регистрацию</a>.
                </li>
                <li>
                    Кликните ссылку, которая прийдет на e-mail после
                    регистрации, и вот, уже почти все готово.
                </li>
                <li>
                    <a href="/login">Заходите</a>, и да прибудет с вами тонкий
                    юмор!
                </li>
            </ol>
            <p>
                Когда вы зарегистрированы на сайте, вы можете писать заходы и
                добивки, а также голосовать за других пользователей.
            </p>
            <h2 id="terms">Терминология</h2>
            <ul>
                <li id="zahod">
                    <b>Заход</b> &mdash; начало шутки или вопрос. В англоязычной
                    юмористической терминологии заходом называют <i>"setup"</i>.
                    Обычно смешной ну или как получится. Заход может быть в
                    стихотворной форме, или в любой другой, но главное, он
                    должен быть таким, чтобы на него можно было ответить. Пишите
                    заход, и скоро на него напишут кучу смешных ответов &mdash;{" "}
                    <i>добивок</i>.
                    <p>
                        Например: <i>Если у вас дома завелась крыса...</i>
                    </p>
                </li>
                <li id="dobivka">
                    <b>Добивка</b> &mdash; окончание шутки или ответ. В
                    англоязычной юмористической терминологии заходом называют{" "}
                    <i>"punchline"</i>. Добивка обычно как раз и есть самая
                    смешная часть <i>репризы</i> (совокупность захода и
                    добивки), хотя иногда и заход бывает смешнее добивки :)
                    <p>
                        Например: <i>Попробуйте ее успокоить.</i>
                    </p>
                </li>
                <li>
                    <b>Реприза</b> &mdash; полноценная текстовая шутка. Обычно
                    реприза состоит из захода и добивки.
                    <p>
                        То есть если взять заход и добивку из первых двух
                        пунктов:
                    </p>
                    <p>
                        <i>
                            Если у вас дома завелась крыса...
                            <br />
                            Попробуйте ее успокоить.
                        </i>
                    </p>
                </li>
            </ul>
            <h2 id="rating">Общий рейтинг</h2>
            <p>
                <b>Общий рейтинг (веселость)</b> &mdash; это колличество баллов,
                которое вы получили за всевозможные активности на сайте.
            </p>
            <p>
                Между прочим, за каждый добавленный вами заход или добивку вы
                получите прибавку к рейтингу: писать на сайте &mdash; выгодно!
            </p>
            <p>
                Чем выше рейтинг, тем выше вы в списке{" "}
                <a href="/users">участников</a>, вот здорово, правда?
            </p>
            <p>
                <b>Как мы считаем рейтинг автора:</b>
            </p>
            <ul>
                <li>
                    <b>+1</b> &mdash; за каждую добавленную вами добивку (но
                    только пока добивок не более 100).
                </li>
                <li>
                    <b>+2</b> &mdash; за каждый добавленный вами заход (но
                    только пока заходов не более 100).
                </li>
                <li>
                    <b>+10</b> &mdash; каждый раз, когда кто-то голосует за вас
                    смайлом (зеленый смайл справа от добивки).
                </li>
                <li>
                    <b>+15</b> &mdash; каждый раз, когда кто-то голосует за вас
                    супер смайлом (красный смайл справа от добивки, ну ооочень
                    смешная добивка).
                </li>
            </ul>
            <p>
                <b>Рейтинг добивки:</b>
            </p>
            <ul>
                <li>
                    <b>+1</b> &mdash; за каждый плюс добивке (плюсовать могут
                    только авторизованные пользователи).
                </li>
            </ul>
            <p>
                <b>Рейтинг захода:</b>
            </p>
            <ul>
                <li>
                    <b>+1</b> &mdash; за каждый плюс любой добивке, написанной
                    на этот заход (да-да, не удивляйтесь).
                </li>
            </ul>

            <h2 id="d-rating">Динамический рейтинг (Топ месяца)</h2>
            <p>
                <b>Динамический рейтинг</b> (далее <b>ДР</b>) или{" "}
                <b>Топ месяца</b> - это рейтинг текущего месяца (обнуляется в
                начале каждого месяца). Он генерируется следующим образом.
            </p>
            <p>
                Учитываются <b>только</b> плюсы за добивки, написанные в текущем
                месяце (то есть если вашу добивку из прошлого месяца кто-то
                плюсанул, в ДР это не будет учитываться). При этом за добивки с
                б<i>о</i>льшим количеством плюсов автор получает больший бонус
                (рассчитывается по ряду Фибоначчи) в ДР, а именно:
            </p>
            <ul>
                <li>
                    1 плюс добивке + <b>0</b> бонусных баллов (ББ)
                </li>
                <li>
                    2 плюса + <b>1</b> ББ
                </li>
                <li>
                    3 плюса + <b>1</b> ББ
                </li>
                <li>
                    4 плюса + <b>2</b> ББ
                </li>
                <li>
                    5 плюсов + <b>3</b> ББ
                </li>
                <li>
                    6 плюсов + <b>5</b> ББ
                </li>
                <li>
                    7 плюса + <b>8</b> ББ
                </li>
                <li>
                    8 плюсов + <b>13</b> ББ
                </li>
                <li>
                    9 плюсов + <b>21</b> ББ
                </li>
                <li>
                    10 плюсов + <b>34</b> ББ
                </li>
                <li>
                    более 10 плюсов + <b>40</b> ББ
                </li>
            </ul>
            <p>
                Таким образом, если вам в этом месяце поставили <b>5</b> плюсов
                за одну добивку и по <b>2</b> плюса за две другие, в сумме у вас
                будет <b>14</b> баллов в ДР (5 + 3 ББ = 8, 2 + 1 ББ = 3, 2 + 1
                ББ = 3).
            </p>

            <p>
                Автор, который закончит месяц на первом месте в ДР получаетбейдж{" "}
                <b>Автор месяца</b> и 200 баллов в рейтинг. Такая вот история :)
            </p>

            <h2 id="voting">Голосование</h2>
            <p>
                А еще можно (и нужно!) голосовать за добивки (если вы уже
                зарегистрировались &mdash; смайлы справа от добивки: смешно и
                оборжака). Вам смешно, а автору приятно. Но голосовать можно
                только за чужое творчество, причем только один раз за каждую
                добивку.
            </p>
            <p>
                Если кто-то плюсанул вашу добивку, рейтинг добивки вырастет (и
                вырастет рейтинг захода, на который она написана), а вместе с
                ним вырастет и ваш рейтинг.
            </p>
            <h2 id="comments">Комментирование</h2>
            <p>
                Не стесняйтесь высказать свое мнение о шутках и авторах, оценили
                &mdash; напишите еще и чего-нибудь приятное (или не очень)
                автору, это очень важно &mdash; высказываться! Давайте делиться
                впечатлениями :)
            </p>
            <h2 id="rooms">Комнатушки</h2>
            <p>
                Если вы хотите "поштурмить" (пописать шутки) с друзьями в
                приватной обстановке, вы можете создать{" "}
                <a href="/rooms" target="_blank">
                    комнатушку
                </a>{" "}
                (приватная комната) и добавить туда только тех авторов, с
                которыми вы хотите писать заходы и добивки, например, при
                подготовке к шоу или КВН.
            </p>
            <p>
                В каждой комнатушке есть свое меню и список авторов, которые
                могут там писать. Хотя, можно также создать и публичную
                комнатушку, где смогут писать все. Такие публичные комнатушки мы
                будем использовать, например, для батлов или конкурсов.
            </p>
            <p>
                Цифра рядом с именем автора &mdash; это общее количество
                голосов, полученные этим автором за их добивки &mdash; своего
                рода рейтинг автора в комнате.
            </p>
            <p>
                На общий рейтинг автора рейтинг, полученный в комнатушках, не
                влияет, а заходы и добивки, написанные в комнатушках, нигде,
                кроме этих самых комнатушек, не отображаются.
            </p>
            <h2 id="grammar">Жи и ши с буквой &quot;и&quot; пиши!</h2>
            <p>
                Ну и не можем не упомянуть грамматику. Мы очень хотим, чтобы наш
                сайт ассоциировался с высокой степенью грамотности материала.
                Поэтому частенько поправляем некоторые ошибочки, хотя, они
                довольно редки :) Но и вы не стесняйтесь поправлять друг друга,
                от этого ведь все только выиграют! Не стесняйтесь и не
                принимайте близко к сердцу, это только на пользу. Увидели ошибку
                &mdash; напишите в комментарий, а мы уж поправим, да и автору
                будет на заметку :)
            </p>
            <h2 id="rules">Правила сайта</h2>
            <div className="rules">
                <div className="section-title">
                    <b>Запрещено</b>
                </div>
                <div className="rule">
                    <div className="heading">
                        <div className="number">01</div>
                        <div className="title">Плагиат</div>
                    </div>
                    <div className="content">
                        <b>А именно:</b> использование материалов каких-либо
                        сторонних ресурсов (Google вам в F1 :)
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">02</div>
                        <div className="title">Обсценная лексика</div>
                    </div>
                    <div className="content">
                        <b>А конкретно:</b> нецензурная брань, мат,
                        сквернословие, ненормативная лексика (и уберите детей от
                        экрана, а если дети &mdash; это вы, перестаньте
                        разговаривать с незнакомцами... матом)
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">03</div>
                        <div className="title">Непристойность, похабщина</div>
                    </div>
                    <div className="content">
                        <b>Куда мы относим:</b> неприкрытые шутки про секс, юмор
                        ниже пояса, туалетный юмор (в общем, то, как бы вы не
                        пошутили на приеме у английской или еще какой-нибудь
                        королевы)
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">04</div>
                        <div className="title">Чернуха и жесть</div>
                    </div>
                    <div className="content">
                        <b>Аз есмь:</b> жестокие шутки про смерть,
                        расчлененочку, физические недостатки людей, употребление
                        наркотиков (ну разве что признаться в убийстве и
                        раскаяться можно у нас)
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">05</div>
                        <div className="title">Троллинг</div>
                    </div>
                    <div className="content">
                        <b>То есть:</b> размещение провокационных сообщений с
                        целью вызвать конфликты и взаимные оскорбления (не
                        кормите их, лучше нас)
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">06</div>
                        <div className="title">"Накручивание" рейтинга</div>
                    </div>
                    <div className="content">
                        <b>Что значит:</b> целенаправленное плюсование или
                        минусование пользователя
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">07</div>
                        <div className="title">Реклама и спам</div>
                    </div>
                    <div className="content">
                        <b>Ну это просто:</b> ну вы поняли (лучше где-нибудь
                        рукми.ру прорекламируйте)
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">08</div>
                        <div className="title">
                            Заведомо несмешные заходы или добивки
                        </div>
                    </div>
                    <div className="content">
                        <b>Прописная истина:</b> лучше меньше, да лучше (...
                        веселей станет всем...)
                    </div>
                </div>

                <div className="section-title">
                    <p>
                        Все, что запрещено, но появляется &mdash; беспощадно
                        удаляется!
                    </p>

                    <p>
                        <b>RUKME.RU</b> оставляет за собой право удалять любые
                        материалы, неуместные на сайте по мнению администрации.
                    </p>
                </div>
            </div>
            <div className="rules">
                <div className="section-title good">Разрешено (и еще как!)</div>
                <div className="rule">
                    <div className="heading">
                        <div className="number good">01</div>
                        <div className="title">
                            Смешули, ржаки, бугагашечки, болтяры
                        </div>
                    </div>
                    <div className="content">
                        <b>Ну вы же понимаете:</b> Смешные заходы и смешные
                        добивки (а не &quot;Меня зовут Сережа, и я
                        алкоголик&quot;, хотя сочувствуем, конечно)
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number good">02</div>
                        <div className="title">Голосовать</div>
                    </div>
                    <div className="content">
                        <b>Тут совсем просто:</b> Голосуйте всегда, все любят
                        получать оценки
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number good">03</div>
                        <div className="title">
                            Предлагать новые идеи и жаловаться на что-нибудь
                        </div>
                    </div>
                    <div className="content">
                        <b>Как бы это сказать:</b> Оставляйте отзывы &mdash; мы
                        их читаем за чашечкой кофе с круасаном
                    </div>
                </div>

                <div className="rule">
                    <div className="heading ">
                        <div className="number good">04</div>
                        <div className="title">Комментировать</div>
                    </div>
                    <div className="content">
                        <b>Доброе слово и собаке приятно:</b> авторы этого очень
                        ждут... да что мы вам говорим, вы же сами такие
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number good">05</div>
                        <div className="title">Have fun!</div>
                    </div>
                    <div className="content">
                        <b>Заключительный аккорд:</b> наслаждайтесь творческой
                        атмосферой и смехом без причины :)
                    </div>
                </div>
            </div>
            <div>
                <p>
                    Все, что запрещено, но появляется &mdash; беспощадно
                    удаляется!
                </p>

                <p>
                    <b>RUKME.RU</b> оставляет за собой право удалять любые
                    материалы, неуместные на сайте по мнению администрации.
                </p>
            </div>
            <p>
                А еще про <b>авторское право</b> не забывайте.
                <br />
                Шутка принадлежит только тому, кто ее написал... Ну и всем
                остальным, конечно :)
                <br />
                Хотя использовать мы имеем право все, что есть на сайте, на свое
                усмотрение, и вы с этим согласны.
            </p>
            <div>
                <b>РУКМИ.РУ</b> &mdash; все права защищены мощным заклятьем!
            </div>
            <p>
                В общем, удачного вам творчества на сайте. Улыбать - это так же
                важно, как и улыбаться :)
            </p>
            <p>
                Ваш <b>РУКМИ.РУ</b>!
            </p>
        </div>
    );
};

export default AboutPageContent_ru;
