import { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { NovelApi } from "../api/novelApi.js";
import Spinner from "../components/common/Spinner.jsx";

const NovelPage = () => {
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [errorMessage, setError] = useState("");
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [part, setPart] = useState(null);
    const [currentPart, setCurrentPart] = useState("");
    const [userInput, setUserInput] = useState("");
    const textareaRef = useRef(null);
    const [isSaved, setIsSaved] = useState(false);
    const { currentUser } = useContext(AuthContext);

    const { getNovelPart, addNovelPart } = NovelApi;

    const handleChange = (event) => {
        //setText(event.target.value);
        if (event.key !== "Enter") {
            setUserInput(event.target.value);
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [userInput]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await getNovelPart(
                id,
                currentUser ? currentUser.id : null
            );

            setIsLoading(false);

            if (!result?.error) {
                setPart(result);
            } else if (result?.error) {
                setError(result.error);
            } else {
                setIsUnavailable(true);
            }
        };

        fetchData();
    }, [id, getNovelPart, currentUser]);

    const handlePressEnter = (event) => {
        if (event.key === "Enter") {
            setCurrentPart((prev) => prev + userInput);
            setUserInput("");
        }
    };

    const handleSubmit = () => {
        setPart((prev) => ({
            ...prev,
            content: prev.content + "\n" + currentPart,
        }));
        setCurrentPart("");
        setIsSaved(true);
    };

    const handleEdit = () => {
        const cp = currentPart;
        setUserInput(cp);
        setCurrentPart("");
    };

    return (
        <div className="standard-page">
            {!isLoading && part && (
                <div className="novel">
                    <div className="parent">
                        {part.content.split("\n").map((part, index) => (
                            <p key={index}>{part}</p>
                        ))}
                    </div>
                    <div className="current" onClick={handleEdit}>
                        {currentPart &&
                            currentPart
                                .split("\n")
                                .map((part, index) => (
                                    <p key={index}>{part}</p>
                                ))}
                    </div>
                    {!isSaved && (
                        <div className="form">
                            <p>&#8659;</p>
                            <textarea
                                ref={textareaRef}
                                value={userInput}
                                onChange={handleChange}
                                onKeyUp={handlePressEnter}
                                placeholder="..."
                                autoFocus={true}
                                rows={1}
                                style={{
                                    overflow: "hidden",
                                    resize: "none",
                                }}
                            />
                        </div>
                    )}
                </div>
            )}

            {!isSaved && (
                <div className="filter-buttons">
                    <button onClick={handleSubmit}>
                        {translations.Forms.Save}
                    </button>
                </div>
            )}

            {isSaved && <p>{translations.Messages.Saved}</p>}

            {errorMessage && (
                <p className="error-message-block">{errorMessage}</p>
            )}

            {isLoading && <Spinner />}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}
        </div>
    );
};

export default NovelPage;
