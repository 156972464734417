import React, { useEffect, useState } from "react";
import { translations } from "../base.js";
import { UserApi } from "../api/userApi.js";
import { Link } from "react-router-dom";
import Spinner from "../components/common/Spinner.jsx";

const OnlineUsersWidget = () => {
    const [users, setUsers] = useState(null);
    const [noData, setNoData] = useState(null);
    const { getOnlineUsers } = UserApi;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getOnlineUsers();

            if (result && result.length > 0 && !result?.error) {
                setUsers(result);
                setNoData(false);
            } else {
                setNoData(true);
            }
        };

        fetchData();
    }, [getOnlineUsers]);

    return (
        <div className="widget-container">
            <div className="widget center-content">
                <div className="widget-content">
                    <div className="heading">
                        {translations.Widgets.OnlineUsers}
                    </div>
                    <div className="content-large">
                        {users &&
                            users.length > 0 &&
                            users.map((user) => (
                                <div className="online-users" key={user.id}>
                                    <Link
                                        to={`/user/${user.id}`}
                                        target="_blank"
                                    >
                                        {user.nick}
                                    </Link>
                                </div>
                            ))}
                        {noData === true && (
                            <div>{translations.Widgets.NoOneOnline}</div>
                        )}
                        {noData !== true && !users && <Spinner />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnlineUsersWidget;
