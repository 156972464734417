import { translations, constants } from "../base.js";

const ContactsPage = () => {
    return (
        <div className="standard-page">
            <h1>{translations.Menu.Contacts}</h1>
            <p className="center">
                {translations.Common.ContactsContent}{" "}
                <a href={"mailto:" + constants.EMAILS.ADMIN}>
                    {constants.EMAILS.ADMIN}
                </a>
                .
            </p>
        </div>
    );
};

export default ContactsPage;
