import React, { useEffect, useState } from "react";
import { translations } from "../base.js";
import { ChildApi } from "../api/childApi.js";
import ChildWidget from "../components/child/ChildWidget.jsx";
import Spinner from "../components/common/Spinner.jsx";

const RandomChildWidget = () => {
    const { getRandomChild } = ChildApi;
    const [child, setChild] = useState(null);
    const [trigger, setTrigger] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowWidget, setIsShowWidget] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await getRandomChild();

            setIsLoading(false);

            if (!result?.error && result.id) {
                setChild(result);
            } else {
                setIsShowWidget(false);
            }
        };

        fetchData();
    }, [trigger, getRandomChild]);

    const handleRefresh = (event) => {
        if (
            !event.target?.classList.contains("share-link") &&
            !event.target?.classList.contains("user-link")
        ) {
            setTrigger((prev) => !prev);
        }
    };

    return (
        isShowWidget && (
            <div className="widget-container">
                <div className="widget random-child" onClick={handleRefresh}>
                    <div className="widget-content">
                        <div className="heading">
                            {translations.Widgets.RandomChild}
                        </div>
                        <div className="child">
                            {child && !isLoading ? (
                                <ChildWidget child={child} />
                            ) : (
                                <Spinner />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default RandomChildWidget;
