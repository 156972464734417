import React, {
    useEffect,
    useState,
    useContext,
    useRef,
    useCallback,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { translations } from "../../base.js";
import { AuthContext } from "../../context/authContext";
import { NotificationApi } from "../../api/notificationApi.js";
import logo from "../../images/logo.png";
import profileIcon from "../../images/profile-16-blue.png";
import menuIcon from "../../images/menu-16-yellow.png";
import notificationsIcon from "../../images/notifications.svg";
import notificationsActiveIcon from "../../images/notifications-active.svg";
import ExtendedMenu from "./ExtendedMenu.jsx";
import ProfileMenu from "./ProfileMenu.jsx";

const Header = () => {
    const location = useLocation();
    const { currentUser } = useContext(AuthContext);
    const [isShowExtendedMenu, setIsShowExtendedMenu] = useState(false);
    const [isShowProfileMenu, setIsShowProfileMenu] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const profileMenuTriggerRef = useRef(null);
    const profileMenuTriggerMobileRef = useRef(null);
    const extendedMenuTriggerRef = useRef(null);
    const extendedMenuTriggerMobileRef = useRef(null);

    const { getUserNotificationCount } = NotificationApi;

    const retrieveNotificationCount = useCallback(async () => {
        const userId = currentUser ? currentUser.id : null;

        if (userId) {
            const countResult = await getUserNotificationCount(userId);

            if (!countResult.error) {
                setNotificationCount(countResult);
            }
        }
    }, [currentUser, getUserNotificationCount]);

    useEffect(() => {
        retrieveNotificationCount();
    }, [currentUser, retrieveNotificationCount, location]);

    const handleToggleExtendedMenu = () => {
        setIsShowExtendedMenu((prev) => !prev);
        setIsShowProfileMenu(false);
    };

    const handleToggleProfileMenu = () => {
        setIsShowProfileMenu((prev) => !prev);
        setIsShowExtendedMenu(false);
    };

    const handleHomePageRedirect = () => {
        window.location.href = "/";
    };

    // Add event listener to register a click outside of the profile and extended menu dropdown
    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    // If clicked anywhere outside of the menu trigger (header icons) close the menu
    const handleClickOutside = (event) => {
        if (
            profileMenuTriggerRef &&
            !profileMenuTriggerRef?.current?.contains(event.target) &&
            profileMenuTriggerMobileRef &&
            !profileMenuTriggerMobileRef.current?.contains(event.target)
        ) {
            setIsShowProfileMenu(false);
        }

        if (
            extendedMenuTriggerRef &&
            !extendedMenuTriggerRef.current?.contains(event.target) &&
            extendedMenuTriggerMobileRef &&
            !extendedMenuTriggerMobileRef.current?.contains(event.target)
        ) {
            setIsShowExtendedMenu(false);
        }
    };

    return (
        <>
            <header>
                <nav>
                    <ul>
                        <li className="logo" onClick={handleHomePageRedirect}>
                            <span>{translations.SiteName}</span>
                            <img src={logo} alt="logo" />
                        </li>
                        <li
                            className={
                                location.pathname === "/" ? "active" : ""
                            }
                        >
                            <Link to={`/`}>{translations.Menu.Parents}</Link>
                        </li>
                        <li
                            className={
                                location.pathname === "/children"
                                    ? "active"
                                    : ""
                            }
                        >
                            <Link to={`/children`}>
                                {translations.Menu.Children}
                            </Link>
                        </li>
                        <li
                            className={
                                location.pathname === "/top" ? "active" : ""
                            }
                        >
                            <Link to={`/top`}>{translations.Menu.Top}</Link>
                        </li>

                        <li
                            className={
                                location.pathname === "/rooms" ? "active" : ""
                            }
                        >
                            <Link to={`/rooms`}>{translations.Menu.Rooms}</Link>
                        </li>

                        <li
                            className={
                                location.pathname === "/about" ? "active" : ""
                            }
                        >
                            <Link to={`/about`}>{translations.Menu.About}</Link>
                        </li>

                        {currentUser ? (
                            <li
                                className="profile-logo image-link"
                                onClick={handleToggleProfileMenu}
                                ref={profileMenuTriggerRef}
                            >
                                <div className="">
                                    <span>
                                        <img
                                            id="profileMenuImage"
                                            src={profileIcon}
                                            alt="profile"
                                        />
                                    </span>
                                </div>
                            </li>
                        ) : (
                            <li>
                                <div className="icon-container">
                                    <Link className="link" to="/login">
                                        {translations.Auth.Login}
                                    </Link>
                                </div>
                            </li>
                        )}
                        {currentUser && (
                            <li className="notifications-logo">
                                <Link className="link" to="/notifications">
                                    <img
                                        src={
                                            notificationCount
                                                ? notificationsActiveIcon
                                                : notificationsIcon
                                        }
                                        alt={translations.Icons.Notifications}
                                        title={translations.Icons.Notifications}
                                    />
                                    {notificationCount > 0 && (
                                        <span className="notification-count">
                                            {notificationCount}
                                        </span>
                                    )}
                                </Link>
                            </li>
                        )}
                        <li
                            ref={extendedMenuTriggerRef}
                            className="menu-logo"
                            onClick={handleToggleExtendedMenu}
                        >
                            <img src={menuIcon} alt="menu" />
                        </li>
                    </ul>
                </nav>
            </header>
            <header className="mobile">
                <div
                    ref={extendedMenuTriggerMobileRef}
                    className="control left"
                    onClick={handleToggleExtendedMenu}
                >
                    <img src={menuIcon} alt="menu" />
                </div>
                <div className="logo" onClick={handleHomePageRedirect}>
                    <span>{translations.SiteName}</span>
                    <img src={logo} alt="logo" />
                </div>
                <div className="control right">
                    {currentUser && (
                        <div className="notifications-logo">
                            <Link className="link" to="/notifications">
                                <img
                                    src={
                                        notificationCount
                                            ? notificationsActiveIcon
                                            : notificationsIcon
                                    }
                                    alt={translations.Icons.Notifications}
                                    title={translations.Icons.Notifications}
                                />
                                {notificationCount > 0 && (
                                    <span className="notification-count">
                                        {notificationCount}
                                    </span>
                                )}
                            </Link>
                        </div>
                    )}
                    {currentUser ? (
                        <div
                            ref={profileMenuTriggerMobileRef}
                            onClick={handleToggleProfileMenu}
                        >
                            <img src={profileIcon} alt="profile" />
                        </div>
                    ) : (
                        <div
                            ref={profileMenuTriggerMobileRef}
                            className="control right"
                            onClick={handleToggleProfileMenu}
                        ></div>
                    )}
                </div>
            </header>

            <ExtendedMenu
                className={
                    isShowExtendedMenu
                        ? "extended-menu"
                        : "extended-menu closed"
                }
            />

            <ProfileMenu
                className={
                    isShowProfileMenu && currentUser
                        ? "extended-menu"
                        : "extended-menu closed"
                }
                notificationCount={notificationCount}
                updateNotificationCount={retrieveNotificationCount}
            />
        </>
    );
};

export default Header;
