import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";

const ConfirmEmailPage = () => {
    const { hash, email } = useParams();
    const [errorMessage, setError] = useState("");
    const [successMessage, setSuccess] = useState("");
    const { confirmEmail } = useContext(AuthContext);

    useEffect(() => {
        const confirm = async () => {
            try {
                const r = await confirmEmail(hash, email);
                const success = r.data?.success;

                if (
                    success &&
                    r.data?.messages &&
                    r.data?.messages?.length > 0
                ) {
                    const message = r.data.messages[0];

                    switch (message) {
                        case "CONFIRMED":
                            setSuccess(translations.Auth.RegisterConfirmed);
                            break;
                        default:
                            setSuccess(translations.Auth.RegisterConfirmed);
                            break;
                    }
                }
            } catch (err) {
                const error = err.response?.data?.error;

                switch (error) {
                    case "USER_NOT_FOUND_OR_INVALID_HASH":
                        setError(translations.Errors.ConfirmLinkInvalid);
                        break;
                    default:
                        setError(translations.Errors.GeneralError);
                        break;
                }
            }
        };
        confirm();
    }, [hash, email, confirmEmail]);

    return (
        <div className="auth">
            <h1>{translations.Auth.ConfirmRegistration}</h1>
            {successMessage && <p className="success">{successMessage}</p>}
            {!successMessage && errorMessage && (
                <p className="error">{errorMessage}</p>
            )}
        </div>
    );
};

export default ConfirmEmailPage;
