import axios from "axios";
import { SERVICE_URL, translations, constants } from "../base.js";
import { endOfWeekWithOptions } from "date-fns/fp";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const CommonApi = {
    /**
     * Gets all users' badges
     *
     * @param {number} count
     */
    getAllBadges: async () => {
        try {
            const endpointUrl = `${SERVICE_URL}/common/allbadges/`;
            const result = await apiClient.get(endpointUrl);

            if (result.data) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },
};
