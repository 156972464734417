import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/authContext.js";
import { constants, translations } from "../../base.js";
import { RoomApi } from "../../api/roomApi.js";
import UserAutocomplete from "../../components/controls/UserAutocomplete.jsx";

const RoomForm = ({ formSubmitted }) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [errorMessage, setError] = useState("");
    const [isFormVisible, setIsFormVisible] = useState(false);
    const { currentUser } = useContext(AuthContext);

    const { addRoom } = RoomApi;

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleIsPrivateChange = (e) => {
        const privateRoom = e.target.checked;

        if (!privateRoom) {
            setParticipants([]);
        }

        setIsPrivate(privateRoom);
    };

    const resetForm = () => {
        setName("");
        setDescription("");
        setParticipants([]);
        setIsPrivate(false);
    };

    const checkName = () => {
        return name.length >= constants.VALIDATION.MIN_CONTENT_SIZE;
    };

    const checkDescription = () => {
        return description.length <= constants.VALIDATION.MAX_CONTENT_SIZE;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!currentUser) {
            return;
        }

        if (!checkName()) {
            setError(translations.Errors.NameTooShort);
            return;
        }

        if (!checkDescription()) {
            setError(translations.Errors.DescriptionTooLong);
            return;
        }

        const userIds = participants.map((item) => item.id);

        if (!userIds.includes(currentUser.id)) {
            userIds.push(currentUser.id);
        }

        const result = await addRoom(
            name,
            description,
            !isPrivate,
            currentUser.id,
            userIds
        );

        if (!result?.error) {
            setIsFormVisible(false);
            formSubmitted(true);
            resetForm();
        } else {
            setError(result.error);
        }
    };

    const handleClose = (e) => {
        resetForm();
        setIsFormVisible(false);
        document.body.classList.remove("no-scroll");
    };

    const showFormHandler = () => {
        setIsFormVisible(true);
        document.body.classList.add("no-scroll");
    };

    const handleParticipantAdded = (newParticipant) => {
        if (
            participants.find((item) => item.id == newParticipant.id) ===
            undefined
        ) {
            setParticipants((prev) => [...prev, newParticipant]);
        }
    };

    const handleParticipantRemoved = (id) => {
        const updatedParticipants = participants.filter(
            (item) => item.id !== id
        );
        setParticipants(updatedParticipants);
    };

    return (
        <>
            {currentUser && !isFormVisible && (
                <div className="new-room">
                    <button onClick={showFormHandler}>
                        {translations.Rooms.NewRoom}
                    </button>
                </div>
            )}
            {currentUser && isFormVisible && (
                <div className="overlay">
                    <div className="overlay-modal large">
                        <div className="overlay-modal-content">
                            <p className="header">
                                {translations.Rooms.NewRoom}
                            </p>
                            <div className="form">
                                <input
                                    type="text"
                                    name="name"
                                    onKeyUp={handleNameChange}
                                    placeholder={translations.Rooms.RoomName}
                                    autoFocus
                                />
                                <textarea
                                    name="description"
                                    className="compact"
                                    onKeyUp={handleDescriptionChange}
                                    placeholder={
                                        translations.Rooms.RoomDescription
                                    }
                                ></textarea>
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        name="isPublic"
                                        onClick={handleIsPrivateChange}
                                    />
                                    <label>{translations.Rooms.Private}</label>
                                </div>

                                {isPrivate && (
                                    <div>
                                        <UserAutocomplete
                                            handleTriggerParentComponent={
                                                handleParticipantAdded
                                            }
                                            placeholder={
                                                translations.Rooms
                                                    .AddParticipants
                                            }
                                        />
                                    </div>
                                )}
                                {isPrivate && participants.length > 0 && (
                                    <div className="autocomplete-selected-items">
                                        {participants.map((user) => (
                                            <div
                                                className="item"
                                                key={user.id}
                                                onClick={() => {
                                                    handleParticipantRemoved(
                                                        user.id
                                                    );
                                                }}
                                            >
                                                {user.nick}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {errorMessage && (
                                    <div className="error">{errorMessage}</div>
                                )}
                            </div>
                            <div className="controls">
                                <button
                                    className="submit"
                                    onClick={handleFormSubmit}
                                    disabled={
                                        !checkName() || !checkDescription()
                                    }
                                >
                                    {translations.Forms.Submit}
                                </button>
                                <button className="close" onClick={handleClose}>
                                    {translations.Forms.Close}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RoomForm;
