import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { translations, constants } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { ParentApi } from "../api/parentApi.js";
import { UserApi } from "../api/userApi.js";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";
import Parent from "../components/parent/Parent.jsx";
import ParentChildren from "../components/parent/ParentChildren.jsx";
import DeleteParentModal from "../components/parent/DeleteParentModal.jsx";

const UserParentsPage = () => {
    const [records, setParents] = useState([]);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [trigger, setTrigger] = useState(false);

    const [selectedParentId, setSelectedParentId] = useState(null);
    const [isShowChildren, setIsShowChildren] = useState(false);
    const [isParentChildrenAutoFocus, setIsParentChildrenAutoFocus] =
        useState(false);

    const [parentToDelete, setParentToDelete] = useState(null);
    const { id } = useParams();

    const { currentUser } = useContext(AuthContext);
    const { deleteParent, getUserParents } = ParentApi;
    const { getUserInfo } = UserApi;

    useEffect(() => {
        async function fetchUserData() {
            if (!id && !currentUser) {
                return;
            }

            const userId = id != null ? id : currentUser.id;
            const result = await getUserInfo(userId);

            if (!result?.error) {
                setUser(result);
            }
        }

        fetchUserData();
    }, [currentUser, getUserInfo, id]);

    useEffect(() => {
        async function fetchData() {
            if (!id && !currentUser) {
                setIsUnavailable(true);
                return;
            }

            setIsLoading(true);
            setIsUnavailable(false);

            const userId = id != null ? id : currentUser.id;

            const result = await getUserParents(
                userId,
                constants.DEFAULTS.PAGE_SIZE,
                lastLoadedId
            );

            setIsLoading(false);

            if (!result.error) {
                if (result.length > 0) {
                    setParents((prevRecords) => [...prevRecords, ...result]);
                }

                if (result.length < constants.DEFAULTS.PAGE_SIZE) {
                    setIsEndOfList(true);
                }
            } else {
                setIsUnavailable(true);
            }
        }

        fetchData();
    }, [lastLoadedId, currentUser, id, getUserParents, trigger]);

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setLastLoadedId(
            records?.length > 0 ? records[records.length - 1].id : 0
        );
    };

    const handleShowChildren = (id, isAutoFocus = false) => {
        setSelectedParentId(id);
        setIsShowChildren(true);
        setIsParentChildrenAutoFocus(isAutoFocus);
        document.body.classList.add("no-scroll");
    };

    const handleHideChildren = () => {
        setIsShowChildren(false);
        document.body.classList.remove("no-scroll");
    };

    // Sets parent with specified id for removal
    const handleDeleteParent = (id) => {
        const selected = records.find((record) => record.id === id);
        setParentToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setParentToDelete(null);
    };

    // Marks selected parent as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (parentToDelete) {
            const result = await deleteParent(parentToDelete.id);

            if (!result?.error) {
                const updatedRecords = records.filter(
                    (record) => record.id !== parentToDelete.id
                );

                setParents(updatedRecords);
            }
        }

        setParentToDelete(null);
    };

    return (
        <div className="parents-content">
            <h1>
                {id != null && (
                    <span>
                        {translations.Parents.UserParents} {user?.nick}
                    </span>
                )}
                {id == null && translations.Menu.MyParents}
            </h1>

            {parentToDelete && (
                <DeleteParentModal
                    parent={parentToDelete}
                    closeModal={handleCloseModal}
                    confirmDelete={handleDeleteComfirmed}
                />
            )}

            {isShowChildren && (
                <ParentChildren
                    id={selectedParentId}
                    hideChildren={handleHideChildren}
                    isAutoFocus={isParentChildrenAutoFocus}
                />
            )}

            {records.map((record) => (
                <Parent
                    key={record.id}
                    parent={record}
                    parentChildrenCnt={record.childrenCnt}
                    showChildren={handleShowChildren}
                    deleteParent={handleDeleteParent}
                />
            ))}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {!isLoading && !isEndOfList && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {isLoading && <Spinner />}

            {!isLoading && isEndOfList && (
                <NoItemsCard isEmpty={records?.length === 0} />
            )}
        </div>
    );
};

export default UserParentsPage;
