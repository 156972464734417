import { useEffect, useState, useContext } from "react";
import { constants, translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { NewsApi } from "../api/newsApi.js";
import News from "../components/news/News.jsx";
import NewsForm from "../components/news/NewsForm.jsx";
import ConfirmDeleteNews from "../components/news/ConfirmDeleteNews.jsx";
import ConfirmModal from "../components/common/ConfirmModal.jsx";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";

const NewsPage = () => {
    const { currentUser } = useContext(AuthContext);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [records, setRecords] = useState([]);
    const [newsToDelete, setNewsToDelete] = useState(null);
    const [errorMessage, setError] = useState("");
    const [trigger, setTrigger] = useState(false);

    const { getNews, deleteNews } = NewsApi;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            const result = await getNews(
                constants.DEFAULTS.PAGE_SIZE_NORMAL,
                lastLoadedId
            );

            if (!result?.error) {
                if (result.length > 0) {
                    setRecords((prevRecords) => [...prevRecords, ...result]);
                }

                if (result.length < constants.DEFAULTS.PAGE_SIZE_NORMAL) {
                    // No more records
                    setIsEndOfList(true);
                }
            } else {
                setError(result.error);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [lastLoadedId, getNews, trigger]);

    // Sets news with specified id for removal
    const handleDeleteNews = (id) => {
        const selected = records.find((record) => record.id === id);
        setNewsToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setNewsToDelete(null);
    };

    // Marks selected news as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (newsToDelete) {
            const result = await deleteNews(newsToDelete.id);

            if (!result?.error) {
                const updatedRecords = records.filter(
                    (record) => record.id !== newsToDelete.id
                );

                setRecords(updatedRecords);
                setIsEndOfList(updatedRecords.length === 0);
            }
        }

        setNewsToDelete(null);
    };

    const handleFormSubmitted = (success) => {
        if (success) {
            setRecords([]);
            setLastLoadedId(-Math.random());
        }
    };

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setLastLoadedId(
            records?.length > 0 ? records[records.length - 1].id : 0
        );
    };

    // Show buttons if:
    // - User is super admin
    const showToAdmin = () => {
        return currentUser?.isSuperAdmin;
    };

    return (
        <div className="standard-page">
            <h1>{translations.Menu.News}</h1>

            {showToAdmin() && <NewsForm formSubmitted={handleFormSubmitted} />}

            {errorMessage && <div className="error">{errorMessage}</div>}

            {records &&
                records.length > 0 &&
                records.map((news) => (
                    <News
                        news={news}
                        deleteNews={handleDeleteNews}
                        key={news.id}
                    />
                ))}

            {newsToDelete && (
                <ConfirmModal
                    contentComponent={<ConfirmDeleteNews news={newsToDelete} />}
                    closeModal={handleCloseModal}
                    confirmDelete={handleDeleteComfirmed}
                />
            )}

            {!isLoading && !isEndOfList && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {isLoading && <Spinner />}

            {!isLoading && isEndOfList && (
                <NoItemsCard isEmpty={records?.length === 0} />
            )}
        </div>
    );
};

export default NewsPage;
