import React from "react";
import { translations } from "../base.js";
import ChildrenPageBase from "./ChildrenPageBase.jsx";

const ChildrenPage = () => {
    const title = translations.Menu.Children;

    return <ChildrenPageBase pageTitle={title} showSidebar={false} />;
};

export default ChildrenPage;
