import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const UserApi = {
    /**
     * Gets top users
     *
     * @param {number} count
     */
    getTopUsers: async (
        count = constants.DEFAULTS.USERS_PAGE_SIZE,
        skip = 0
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/users/top/${count}/${skip}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets users by keyword
     *
     * @param {string} keyword
     */
    getUsersByKeyword: async (
        keyword,
        count = constants.DEFAULTS.PAGE_SIZE_NORMAL
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/users/search/${keyword}/${count}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets user's info
     *
     * @param {number} id
     */
    getUserInfo: async (id) => {
        try {
            const endpointUrl = `${SERVICE_URL}/users/info/${id}`;

            const result = await apiClient.get(endpointUrl);

            return result.data;
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "USER_NOT_FOUND":
                    error = translations.Errors.UserInfoNotFound;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }
    },

    /**
     * Gets user of the week
     *
     * @param {string} startDate
     */
    getTopUserOfThePreviousWeek: async () => {
        try {
            const endpointUrl = `${SERVICE_URL}/users/userofthepreviousweek/`;

            const result = await apiClient.get(endpointUrl);

            return result.data;
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "USER_NOT_FOUND":
                    error = translations.Errors.UserNotFound;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }
    },

    /**
     * Gets user of the month
     *
     */
    getTopUserOfThePreviousMonth: async () => {
        try {
            const endpointUrl = `${SERVICE_URL}/users/userofthepreviousmonth`;

            const result = await apiClient.get(endpointUrl);

            return result.data;
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "USER_NOT_FOUND":
                    error = translations.Errors.UserNotFound;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }
    },

    /**
     * Gets user of the year
     *
     */
    getTopUserOfThePreviousYear: async () => {
        try {
            const endpointUrl = `${SERVICE_URL}/users/userofthepreviousyear/`;

            const result = await apiClient.get(endpointUrl);

            return result.data;
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "USER_NOT_FOUND":
                    error = translations.Errors.UserNotFound;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }
    },

    /**
     * Gets online users
     *
     */
    getOnlineUsers: async () => {
        try {
            const endpointUrl = `${SERVICE_URL}/logs/getOnlineUsers`;

            const result = await apiClient.get(endpointUrl);

            return result.data;
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }
    },

    /**
     * Gets dynamic ratings
     * If current === true - returns current month, otherwise - previous month
     *
     */
    getDynamicRatings: async (current = true) => {
        try {
            const endpointUrl = current
                ? `${SERVICE_URL}/users/getCurrentDynamicRatings`
                : `${SERVICE_URL}/users/getPreviousMonthDynamicRatings`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },
};
