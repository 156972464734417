import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const MessageApi = {
    /**
     * Gets user recent chats
     *
     * @param {number} userId current user's id
     * @param {number} count number of chats
     */
    getUserRecentChats: async (
        userId,
        recipientId = null,
        count = constants.DEFAULTS.PAGE_SIZE_NORMAL
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/messages/chats/${userId}/${recipientId}/${count}/`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets new user messages count
     *
     * @param {number} userId current user's id
     */
    getUnreadMessagesCount: async (userId) => {
        try {
            const endpointUrl = `${SERVICE_URL}/messages/unreadcount/${userId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return 0;
    },

    /**
     * Gets chat's messages
     *
     * @param {number} userId current user's id
     * @param {number} chatId chat's id
     * @param {number} lastLoadedId last loaded message's id
     * @param {number} count number of messages to retrieve
     */
    getChatMessages: async (
        userId,
        chatId,
        count = constants.DEFAULTS.PAGE_SIZE,
        lastLoadedId
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/messages/chatmessages/${userId}/${chatId}/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Adds a message
     *
     * @param {string} content
     * @param {number} recipientId
     * @param {number} senderId
     */
    addMessage: async (content, recipientId, senderId) => {
        try {
            const endpointUrl = `${SERVICE_URL}/messages/add`;
            const params = {
                content: content,
                recipientId: recipientId,
                senderId: senderId,
            };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "MESSAGE_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },
};
