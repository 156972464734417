const ConfirmDeleteChild = ({ child }) => {
	return (
		<>
			<div className="parent-content">
				{child.parentContent.split("\n").map((item, key) => {
					return <p key={key}>{item}</p>;
				})}
			</div>
			<div className="child-content">
				{child.content.split("\n").map((item, key) => {
					return <p key={key}>{item}</p>;
				})}
			</div>
		</>
	);
};

export default ConfirmDeleteChild;
