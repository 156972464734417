import React from "react";
import { Link } from "react-router-dom";
import { translations } from "../base.js";

const RoomLinks = ({ room }) => {
    return (
        room && (
            <div className="widget-container">
                <div className="widget room">
                    <div className="widget-content">
                        <div className="title">
                            {translations.Rooms.RoomLinks}
                        </div>

                        <div className="menu">
                            <div className="menu-item">
                                <Link to={`/roomp/${room.id}`}>
                                    {translations.Menu.RoomParents}
                                </Link>
                            </div>

                            <div className="menu-item">
                                <Link to={`/roomc/${room.id}`}>
                                    {translations.Menu.RoomChildren}
                                </Link>
                            </div>

                            <div className="menu-item">
                                <Link to={`/rooms`}>
                                    {translations.Menu.Rooms}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default RoomLinks;
