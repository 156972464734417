import React, { useState, useContext, useRef } from "react";
import { constants, translations } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";
import { MessageApi } from "../../api/messageApi.js";

const MessageForm = ({ recipientId, formSubmitted }) => {
    const [inputs, setInputs] = useState({ content: "" });
    const [errorMessage, setError] = useState("");
    const { currentUser } = useContext(AuthContext);
    const messageRef = useRef(null);

    const { addMessage } = MessageApi;

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const resetForm = () => {
        Object.keys(inputs).forEach((key) => {
            setInputs((prevInputs) => ({ ...prevInputs, [key]: "" }));
        });

        messageRef.current.value = "";
    };

    const checkContent = () => {
        return (
            inputs["content"].length >= constants.VALIDATION.MIN_CONTENT_SIZE
        );
    };

    const handleFormSubmit = async (e) => {
        e.target.disabled = true;
        e.preventDefault();

        if (!checkContent()) {
            setError(translations.Errors.ContentTooShort);
            return;
        }

        const result = await addMessage(
            inputs.content,
            recipientId,
            currentUser?.id
        );

        if (!result?.error) {
            resetForm();
            formSubmitted(true);
        } else {
            setError(result.error);
        }
    };

    return (
        <>
            <form className="message-form">
                <textarea
                    name="content"
                    onKeyUp={handleChange}
                    ref={messageRef}
                    placeholder={translations.Chats.SendMessage}
                ></textarea>
                <div className="actions">
                    <button
                        className="submit"
                        onClick={handleFormSubmit}
                        disabled={!checkContent()}
                    >
                        {translations.Forms.Send}
                    </button>
                </div>
            </form>
            {errorMessage && <div className="error">{errorMessage}</div>}
        </>
    );
};

export default MessageForm;
