import React, { useState, useContext, useEffect } from "react";
import { translations, constants } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";

const FeedbackButton = ({ showForm }) => {
    const { currentUser } = useContext(AuthContext);

    const handleButtonClick = () => {
        showForm();
    };

    return (
        currentUser && (
            <button className="feedback-button" onClick={handleButtonClick}>
                {translations.Feedbacks.Feedback}
            </button>
        )
    );
};

export default FeedbackButton;
