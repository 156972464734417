import React, { useEffect, useState } from "react";
import { translations, constants } from "../base.js";
import { UserApi } from "../api/userApi.js";
import { Link } from "react-router-dom";
import Spinner from "../components/common/Spinner.jsx";

const TopUsersWidget = () => {
    const [users, setUsers] = useState(null);
    const { getTopUsers } = UserApi;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getTopUsers(
                constants.DEFAULTS.PAGE_SIZE_NORMAL,
                0
            );

            if (!result?.error) {
                setUsers(result);
            }
        };

        fetchData();
    }, [getTopUsers]);

    return (
        <div className="widget-container">
            <div className="widget">
                <div className="widget-content">
                    <div className="heading">
                        {translations.Widgets.TopUsers}
                    </div>
                    <div>
                        {users && users.length > 0 ? (
                            <ol>
                                {users.map((user) => (
                                    <li className="top-users" key={user.id}>
                                        <Link
                                            to={`/user/${user.id}`}
                                            target="_blank"
                                        >
                                            {user.nick}
                                        </Link>{" "}
                                        (
                                        {user.rating > 1000
                                            ? `${Math.floor(
                                                  user.rating / 1000
                                              )}k`
                                            : user.rating}
                                        )
                                    </li>
                                ))}
                            </ol>
                        ) : (
                            <Spinner />
                        )}

                        <div className="centered">
                            <Link to="/users">
                                {translations.Widgets.SeeAll}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopUsersWidget;
