import Loader from "../../images/loader.svg";

const Spinner = () => {
	return (
		<div className="loader">
			<img src={Loader} alt="Loader" />
		</div>
	);
};

export default Spinner;
