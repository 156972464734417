import { useEffect, useState, useContext, useRef } from "react";
import Child from "../child/Child";
import Spinner from "../common/Spinner";
import CloseButton from "../../images/close.svg";
import ParentChildForm from "./ParentChildForm.jsx";
import ConfirmDeleteChild from "../../components/child/ConfirmDeleteChild";
import ConfirmModal from "../../components/common/ConfirmModal";
import NoItemsCard from "../../components/common/NoItemsCard.jsx";
import { AuthContext } from "../../context/authContext";
import { ParentApi } from "../../api/parentApi.js";
import { ChildApi } from "../../api/childApi.js";
import { Link } from "react-router-dom";

const ParentChildren = ({ id, hideChildren, isAutoFocus = false }) => {
    const [parent, setParent] = useState(null);
    const [children, setChildren] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [childToDelete, setChildToDelete] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const containerRef = useRef(null);
    const { getParentChildren } = ParentApi;
    const { deleteChild } = ChildApi;

    useEffect(() => {
        setChildren(null);

        async function fetchData() {
            setIsLoading(true);
            const result = await getParentChildren(id, currentUser?.id);
            setIsLoading(false);

            if (!result.error) {
                const { id, content, userId, nick, created, roomId } = result;
                setParent({ id, content, userId, nick, created, roomId });

                if (result.children) {
                    setChildren(result.children);
                }

                setIsEndOfList(result.children.length === 0);
            } else {
                // Error handling
            }
        }

        fetchData();
    }, [trigger, currentUser, id, getParentChildren]);

    const handleParentChildFormSubmitted = (success) => {
        if (success) {
            setTrigger(!trigger);
            setIsEndOfList(false);
        }
    };

    // Sets child with specified id for removal
    const handleDeleteChild = (id) => {
        const selected = children.find((record) => record.id === id);
        setChildToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setChildToDelete(null);
    };

    // Marks selected child as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (childToDelete) {
            const result = await deleteChild(childToDelete.id);

            if (!result.error) {
                const updatedRecords = children.filter(
                    (record) => record.id !== childToDelete.id
                );
                setChildren(updatedRecords);

                if (updatedRecords.length === 0) {
                    setIsEndOfList(true);
                }
            }
        }

        setChildToDelete(null);
    };

    // Add event listener to register a click outside of the autocomplete text field
    useEffect(() => {
        // If clicked anywhere outside of the autocomplete text field - reset the field and the autocomplete results
        const handleClickOutside = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                hideChildren();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [hideChildren]);

    return (
        <div className="overlay">
            <div className="parent-children" ref={containerRef}>
                <div className="header">
                    <div
                        aria-label="Close"
                        role="button"
                        className="close round"
                        onClick={hideChildren}
                    >
                        <img src={CloseButton} alt="Close" />
                    </div>
                    <div className="title">
                        <Link to={`/parent/${parent?.id}`} target="_blank">
                            {parent?.content}
                        </Link>
                    </div>
                </div>

                {currentUser && parent && (
                    <ParentChildForm
                        parent={parent}
                        childAdded={handleParentChildFormSubmitted}
                        isAutoFocus={isAutoFocus}
                    />
                )}

                {childToDelete && (
                    <ConfirmModal
                        contentComponent={
                            <ConfirmDeleteChild child={childToDelete} />
                        }
                        closeModal={handleCloseModal}
                        confirmDelete={handleDeleteComfirmed}
                    />
                )}

                <div className="content">
                    {children &&
                        children.length > 0 &&
                        children.map((item) => (
                            <Child
                                key={item.id}
                                child={{
                                    ...item,
                                    parentContent: parent.content,
                                }}
                                hasParentLink={false}
                                deleteChild={handleDeleteChild}
                            />
                        ))}

                    {isLoading && <Spinner />}

                    {isEndOfList && (
                        <NoItemsCard isEmpty={children?.length === 0} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ParentChildren;
