const AboutPageContent_en = () => {
    return (
        <div className="content">
            <p>
                Welcome to the site where the users (you!) create{" "}
                <b>one-liner</b> jokes, vote for the jokes written by other
                users, and compete for the honor to be called the funniest!
            </p>
            <p>
                Think <b>World's Worst</b> game from{" "}
                <a
                    href="https://en.wikipedia.org/wiki/Whose_Line_Is_It_Anyway%3F_(American_TV_series)"
                    target="_blank"
                >
                    Whose Line Is It Anyway
                </a>{" "}
                when you are given one topic, say{" "}
                <i>World's worst person to stuck in an elevator with</i>, and
                you have to come up with a funny line to it. For example:
            </p>
            <ul>
                <li>
                    <b>[setup]</b> World's worst person to stuck in an elevator
                    with.
                </li>
                <li>
                    <b>[punchline]</b> "Do you mind if I smoke?"
                </li>
            </ul>
            <p>Or it can be any type of a setup (opening line):</p>{" "}
            <ul>
                <li>
                    <b>[setup]</b> What do you call a dog who is always hungry?
                </li>
                <li>
                    <b>[punchline]</b> A dog.
                </li>
            </ul>
            <p>Jokes, puns, limericks &mdash; we have it all&nbsp;:)</p>
            <h2>How it all works</h2>
            <ul>
                <li>
                    <a href="#start">How to start</a>
                </li>
                <li>
                    <a href="#terms">Terminology</a>
                </li>
                <li>
                    <a href="#rating">Author rating</a>
                </li>
                <li>
                    <a href="#d-rating">Dynamic rating</a>
                </li>
                <li>
                    <a href="#voting">Voting</a>
                </li>
                <li>
                    <a href="#comments">Commenting</a>
                </li>
                <li>
                    <a href="#rooms">Rooms</a>
                </li>
                <li>
                    <a href="#rules">Site rules</a>
                </li>
            </ul>
            <h2 id="start">How to start</h2>
            <p>
                It's super simple. You can just read and laugh (fingers
                crossed), or you can participate. If you want to write jokes and
                vote for other authors' humour, just take 3 little steps:
            </p>
            <ol>
                <li>
                    Complete a simple <a href="/register">registration</a>.
                </li>
                <li>
                    Click the link sent to your email after registration, and
                    you're almost done.
                </li>
                <li>
                    <a href="/login">Log in</a>, and may the humour be with you!
                </li>
            </ol>
            <h2 id="terms">Terminology</h2>
            <ul>
                <li id="zahod">
                    <b>Setup</b> &mdash; the beginning of the joke or just a
                    question. It’s usually funny or as good as it can be. For
                    example:
                    <p>
                        <i>If the rats are running away from the ship...</i>
                    </p>
                </li>
                <li id="dobivka">
                    <b>Punchline</b> &mdash; the end of a joke or an answer. For
                    example:
                    <p>
                        <i>Maybe they have a swimming lesson.</i>
                    </p>
                </li>
                <li>
                    <b>Joke</b> &mdash; <b>a setup</b> and <b>a punchline</b>{" "}
                    make a <b>joke</b>!
                    <p>
                        <i>If the rats are running away from the ship...</i>
                        <br />
                        <i>Maybe they have a swimming lesson.</i>
                    </p>
                </li>
            </ul>
            <h2 id="rating">Author rating</h2>
            <p>
                <b>Author rating (Funniness)</b> is the number of points you
                have received for various activities on the site.
            </p>
            <p>
                By the way, for every setup or punchline you add, you get an
                increase in your rating. Writing on the site is rewarding!
            </p>
            <p>
                The higher your rating, the higher you are on the list of{" "}
                <a href="/users">authors</a>. Isn't that fun!?
            </p>
            <p>
                <b>How we calculate the author's rating:</b>
            </p>
            <ul>
                <li>
                    <b>+1</b> &mdash; for each added punchline (but only until
                    the number of your punchlines exceeds 100).
                </li>
                <li>
                    <b>+2</b> &mdash; for each added setup (but only until the
                    number of your setups exceeds 100).
                </li>
                <li>
                    <b>+10</b> &mdash; each time someone votes for you with a
                    green smiley right to the punchline (Funny).
                </li>
                <li>
                    <b>+15</b> &mdash; each time someone votes for you with a
                    red smiley right to the punchline (Hilarious).
                </li>
            </ul>
            <p>
                Each punchline also has its own rating - number of times anyone
                voted for it (any smiley)
            </p>
            <h2 id="d-rating">Dynamic rating (Top of the month)</h2>
            <p>
                <b>Dynamic rating</b> (<b>DR</b>) or <b>Top of the month</b> is
                the rating for the current month (resets at the beginning of
                each month). It is generated as follows.
            </p>
            <p>
                <b>Only</b> votes for punchlines written in the current month
                are counted (if your punchline from the previous month gets a
                vote, it won't be counted in DR). For punchlines with a bigger
                number of votes, the author receives a larger bonus (calculated
                according to the Fibonacci sequence) in the DR:
            </p>
            <ul>
                <li>
                    1 vote to a punchline + <b>0</b> bonus points (BP)
                </li>
                <li>
                    2 votes + <b>1</b> BP
                </li>
                <li>
                    3 votes + <b>1</b> BP
                </li>
                <li>
                    4 votes + <b>2</b> BP
                </li>
                <li>
                    5 votes + <b>3</b> BP
                </li>
                <li>
                    6 votes + <b>5</b> BP
                </li>
                <li>
                    7 votes + <b>8</b> BP
                </li>
                <li>
                    8 votes + <b>13</b> BP
                </li>
                <li>
                    9 votes + <b>21</b> BP
                </li>
                <li>
                    10 votes + <b>34</b> BP
                </li>
                <li>
                    more than 10 votes + <b>40</b> BP
                </li>
            </ul>
            <p>
                So, if you got 5 votes for one punchline and 2 votes for two
                others this month, you will have a total of 14 points in DR (5
                votes + 3 BP = 8, 2 votes + 1 BP = 3, 2 votes + 1 BP = 3).
            </p>
            <p>
                The author who finishes the month in first place in DR receives
                the badge <b>Author of the Month</b> and <b>200</b> points in
                the rating. That's the deal :)
            </p>
            <h2 id="voting">Voting</h2>
            <p>
                And you can (and should!) vote for punchlines (if you are
                already registered, smileys to the right of the punchline:
                <b>Funny</b> and <b>Hilarious</b>). It makes you laugh, and the
                author is happy to be praised. But you can only vote for others'
                creativity, and only once for each punchline.
            </p>
            <p>
                If someone votes your punchline, the rating of the punchline
                will increase, and your rating will grow along with it.
            </p>
            <h2 id="comments">Commenting</h2>
            <p>
                Don't hesitate to express your opinion about the jokes and
                authors, if you liked a joke, write something pleasant (or not
                so much) to the author, it's very important to express yourself!
                Let's share our impressions :)
            </p>
            <h2 id="rooms">Rooms</h2>
            <p>
                If you want to "brainstorm" jokes with friends in private, you
                can create a{" "}
                <a href="/rooms" target="_blank">
                    room
                </a>{" "}
                and add only those authors with whom you want to write setups
                and punchlines, for example, when preparing for a show or a
                standup routine.
            </p>
            <p>
                Each room has its own menu and a list of authors who can write
                there. However, you can also create a public room where anyone
                can write. We will use such public small rooms, for example, for
                comedy battles or contests.
            </p>
            <p>
                The number next to the author's name is the total number of
                votes this author has received for their punchlines — author's
                ranking in the room.
            </p>
            <p>
                The ranking received in rooms does not affect the author's
                overall rating, and the setups and punchlines written in rooms
                are not displayed anywhere except in those same rooms.
            </p>
            <h2 id="rules">Site rules</h2>
            <div className="rules">
                <div className="section-title">
                    <b>Not allowed</b>
                </div>
                <div className="rule">
                    <div className="heading">
                        <div className="number">01</div>
                        <div className="title">Plagiarism</div>
                    </div>
                    <div className="content">
                        Please, do not use materials from other sources or the
                        jokes you have already heard/read (or Google it if not
                        sure :).
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">02</div>
                        <div className="title">Obscene language</div>
                    </div>
                    <div className="content">
                        Please, do not use foul language, swearing, profanity
                        (think what an actor could say from a TV screen and what
                        couldn't).
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">03</div>
                        <div className="title">
                            Dark humor and extreme content
                        </div>
                    </div>
                    <div className="content">
                        Please, do not use cruel jokes about death,
                        dismemberment, people's physical flaws, drug use.
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">04</div>
                        <div className="title">"Boosting" ratings</div>
                    </div>
                    <div className="content">
                        Please, do not deliberately upvote a user, just follow
                        your heart: if it's funny, vote for it, if not &mdash;
                        move on.
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number">05</div>
                        <div className="title">Advertising and spam</div>
                    </div>
                    <div className="content">Well, you know...</div>
                </div>

                <div className="section-title">
                    <p>
                        All that is prohibited but appears on the site will be
                        mercilessly deleted. And then we'll say &mdash; I told
                        you :)
                    </p>

                    <p>
                        <b>LetsBeFunny</b> reserves the right to delete any
                        materials deemed inappropriate on the site by the
                        administration.
                    </p>
                </div>
            </div>
            <div className="rules">
                <div className="section-title good">Allowed (hell yeah!)</div>
                <div className="rule">
                    <div className="heading">
                        <div className="number good">01</div>
                        <div className="title">Jokes, puns, limericks</div>
                    </div>
                    <div className="content">
                        You name it! Anything funny works!
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number good">02</div>
                        <div className="title">Voting</div>
                    </div>
                    <div className="content">
                        Simple: if you like it &mdash; vote for it! Everyone
                        enjoys feeling appreciated ;)
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number good">03</div>
                        <div className="title">Provide feedback</div>
                    </div>
                    <div className="content">
                        Please, send us your suggestions, complaints or even
                        compliments. We want to improve our site and your
                        feedback is essential for this!
                    </div>
                </div>

                <div className="rule">
                    <div className="heading ">
                        <div className="number good">04</div>
                        <div className="title">Commenting</div>
                    </div>
                    <div className="content">
                        Comment on the jokes if you like it or if you think it
                        could work better with some improvement. Everyone likes
                        to chat, so do you, right? ;)
                    </div>
                </div>

                <div className="rule">
                    <div className="heading">
                        <div className="number good">05</div>
                        <div className="title">Have fun!</div>
                    </div>
                    <div className="content">
                        The main rule we have here &mdash; enjoy creating
                        humour, enjoy reading humour, enjoy fun :)
                    </div>
                </div>
            </div>
            <p>
                Don't forget about the copyrights.
                <br />
                A joke belongs to whoever wrote it! And everyone else of course
                :)
                <br />
                LetsBeFunny can use any content that users create on the site
                and you agreed to it.
            </p>
            <div>
                <b>LetsBeFunny.com</b> &mdash; all rights protected by a
                powerful spell!
            </div>
            <p>
                Good luck with your creativity on the site. Laughing is as
                important as making others laugh :)
            </p>
            <p>
                Yours, <b>LetsBeFunny</b>!
            </p>
        </div>
    );
};

export default AboutPageContent_en;
