import React from "react";
import { getTimeAgo } from "../../utils/dates.js";
import { translations } from "../../base.js";

const Message = ({ message, chat }) => {
    return (
        <div
            className={
                message.senderId === chat.id
                    ? message.isRead
                        ? "message-container"
                        : "message-container new"
                    : "message-container right"
            }
        >
            <div className="nick">
                {message.senderId === chat.id
                    ? chat.nick
                    : translations.Messages.Me}
            </div>
            <div className="date">{getTimeAgo(message.created, true)}</div>
            <div className="recent-content">
                {message.recentContent.split("\n").map((item, key) => {
                    return <div key={key}>{item}</div>;
                })}
            </div>
            {false && message.repliedContent && (
                <div className="more-content">
                    <div>...</div>
                    {message.repliedContent.split("\n").map((item, key) => {
                        return <div key={key}>{item}</div>;
                    })}
                </div>
            )}
        </div>
    );
};

export default Message;
