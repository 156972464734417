import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const ChildApi = {
    /**
     * Gets children
     *
     * @param {number} roomId 
     * @param {number} count
     * @param {number} lastLoadedId
     * @param {number} userId current user's id
     
     */
    getChildren: async (
        roomId = null,
        count = constants.DEFAULTS.PAGE_SIZE,
        lastLoadedId = 0,
        userId = null
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/list/${roomId}/${count}/${lastLoadedId}/${userId}`;
            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets children with the reting provided or above
     *
     * @param {number} startWith
     * @param {number} userId
     * @param {number} count
     * @param {number} minRating
     */
    getTopChildren: async (
        type = constants.LIST_TYPE.RECENT,
        userId = null,
        count = constants.DEFAULTS.PAGE_SIZE,
        startWith = 0,

        minRating = constants.DEFAULTS.TOP_CHILD_RATING
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/top/${type}/${count}/${startWith}/${minRating}/${userId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets user's favorite children
     *
     * @param {number} userId
     * @param {number} lastLoadedId
     * @param {number} count
     */
    getFavoriteChildren: async (
        userId,
        count = constants.DEFAULTS.PAGE_SIZE,
        skip = 0
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/favorites/${userId}/${count}/${skip}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "USER_NOT_SPECIFIED":
                    error = translations.Errors.UserNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets user's children by date
     *
     * @param {number} lastLoadedId
     * @param {number} count
     */
    getUserChildren: async (
        userId,
        count = constants.DEFAULTS.PAGE_SIZE,
        lastLoadedId = 0,
        currentUserId = null
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/user/${userId}/${count}/${lastLoadedId}/${currentUserId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "USER_NOT_SPECIFIED":
                    error = translations.Errors.UserNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets user's children by rating
     *
     * @param {number} lastLoadedId
     * @param {number} count
     */
    getUserChildrenByRating: async (
        userId,
        count = constants.DEFAULTS.PAGE_SIZE,
        skip = 0,
        currentUserId = null
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/userr/${userId}/${count}/${skip}/${currentUserId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "USER_NOT_SPECIFIED":
                    error = translations.Errors.UserNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Deletes the child
     *
     * @param {number} id
     */
    deleteChild: async (id) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/delete`;
            const params = { id: id };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Adds a child
     *
     * @param {string} content
     * @param {number} parent
     * @param {number} userId
     */
    addChild: async (content, parent, userId) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/add`;
            const params = {
                content: content,
                parentId: parent.id,
                roomId: parent.roomId,
                userId: userId,
            };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "CHILD_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Edits the child
     *
     * @param {number} id
     * @param {string} content
     */
    editChild: async (id, content) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/edit`;
            const params = { id: id, content: content };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "CHILD_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Adds a user's vote for the child
     *
     * @param {number} childId
     * @param {number} userId
     * @param {number} voteType
     */
    voteForChild: async (childId, userId, voteType = constants.VOTE.SINGLE) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/vote`;
            const params = { userId: userId, childId: childId, vote: voteType };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Adds the child to user's favorites
     *
     * @param {number} childId
     * @param {number} userId
     */
    addChildToFavorites: async (childId, userId) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/addToFavorites`;
            const params = { userId: userId, scrapId: childId };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "DENIED_ADDING_ALREADY_IN_FAVORITES":
                    error = translations.Children.AlreadyInFavorites;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Remove the child from user's favorites
     *
     * @param {number} childId
     * @param {number} userId
     */
    removeChildFromFavorites: async (childId, userId) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/removeFromFavorites`;
            const params = { userId: userId, scrapId: childId };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "DENIED_ADDING_ALREADY_IN_FAVORITES":
                    error = translations.Children.AlreadyInFavorites;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Gets random child
     *
     * @param {number} minRating
     */
    getRandomChild: async (
        minRating = constants.DEFAULTS.RANDOM_CHILD_RATING
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/random/${minRating}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return {};
    },

    /**
     * Gets child of the week
     *
     * @param {string} startDate
     */
    getTopChildOfThePreviousWeek: async () => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/childOfThePreviousWeek/`;

            const result = await apiClient.get(endpointUrl);

            return result.data;
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }
    },

    /**
     * Gets child by id
     *
     * @param {number} id
     */
    getChild: async (id, userId = null) => {
        try {
            const endpointUrl = `${SERVICE_URL}/children/child/${id}/${userId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "CHILD_NOT_FOUND":
                    error = translations.Children.ChildNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return {};
    },
};
