import { translations } from "../../base.js";

const ConfirmModal = ({ contentComponent, closeModal, confirmDelete }) => {
    return (
        <div className="overlay">
            <div className="overlay-modal">
                <div className="overlay-modal-content">
                    <p className="header">
                        {translations.Forms.AreYouSureDelete}:
                    </p>
                    <div className="content">{contentComponent}</div>
                    <div className="controls">
                        <button className="submit" onClick={confirmDelete}>
                            {translations.Forms.Delete}
                        </button>
                        <button className="close" onClick={closeModal}>
                            {translations.Forms.Cancel}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
