export const checkUserAccess = (id, room) => {
    if (room === null) {
        return false;
    }

    if (room?.isPublic) {
        return true;
    } else {
        if (id === null) {
            return false;
        }

        const isParticipant = room.users?.some((user) => user.id == id);

        if (room.owerId == id || isParticipant) {
            return true;
        } else {
            return false;
        }
    }
};

export const checkUserAdminAccess = (id, room) => {
    if (id === null || room === null) {
        return false;
    }

    if (room.ownerId && id == room.owerId) {
        return true;
    } else {
        const isAdmin = room.users?.some(
            (user) => user.id == id && user.isAdmin == 1
        );

        return isAdmin;
    }
};
