import moment from "moment-timezone";
import { translations } from "./../base.js";

export const getLocalDateString = (
    utcDateToFormat,
    dateFormat = "DD.MM.YYYY HH:mm"
) => {
    return moment(utcDateToFormat).format(dateFormat);
};

export const getTimeAgo = (dateString, includeTime = false) => {
    const now = moment();
    const dateFromString = moment(dateString);

    const secondsDifference = now.diff(dateFromString, "seconds");
    const minutesDifference = now.diff(dateFromString, "minutes");
    const hoursDifference = now.diff(dateFromString, "hours");
    const daysDifference = now.diff(dateFromString, "days");

    if (secondsDifference < 60) {
        return translations.DateAndTime.JustNow;
    } else if (minutesDifference < 60) {
        if (
            minutesDifference === 1 ||
            (minutesDifference !== 11 && minutesDifference % 10 === 1)
        ) {
            return `${minutesDifference} ${translations.DateAndTime.MinuteAgo}`;
        } else if (
            [2, 3, 4].includes(minutesDifference) ||
            (![12, 13, 14].includes(minutesDifference) &&
                (minutesDifference % 10 === 2 ||
                    minutesDifference % 10 === 3 ||
                    minutesDifference % 10 === 4))
        ) {
            return `${minutesDifference} ${translations.DateAndTime.MinutesAgo_2_3_4}`;
        }
        return `${minutesDifference} ${translations.DateAndTime.MinutesAgo}`;
    } else if (hoursDifference < 24) {
        if (
            hoursDifference === 1 ||
            (hoursDifference !== 11 && hoursDifference % 10 === 1)
        ) {
            return `${hoursDifference} ${translations.DateAndTime.HourAgo}`;
        } else if (
            [2, 3, 4].includes(hoursDifference) ||
            (![12, 13, 14].includes(hoursDifference) &&
                (hoursDifference % 10 === 2 ||
                    hoursDifference % 10 === 3 ||
                    hoursDifference % 10 === 4))
        ) {
            return `${hoursDifference} ${translations.DateAndTime.HoursAgo_2_3_4}`;
        }
        return `${hoursDifference} ${translations.DateAndTime.HoursAgo}`;
    } else if (
        daysDifference === 1 &&
        dateFromString.date() === now.date() - 1
    ) {
        return translations.DateAndTime.Yesterday;
    } else {
        return includeTime
            ? dateFromString.format("DD.MM.yyyy HH:mm")
            : dateFromString.format("DD.MM.yyyy");
    }
};
