import { useContext, useState, useEffect } from "react";
import { translations, constants } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";
import { CommentApi } from "../../api/commentApi.js";
import Comment from "./Comment.jsx";
import Spinner from "../common/Spinner.jsx";
import CommentForm from "./CommentForm.jsx";
import ConfirmDeleteComment from "./ConfirmDeleteComment.jsx";
import ConfirmModal from "../common/ConfirmModal.jsx";

const Comments = ({ childId }) => {
    const [records, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [trigger, setTrigger] = useState(false);

    const { getItemComments, deleteComment } = CommentApi;

    const handleFormSubmitted = (success) => {
        if (success) {
            setComments([]);
            setLastLoadedId(0);
            setTrigger((prev) => !prev);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            const result = await getItemComments(
                childId,
                constants.DEFAULTS.PAGE_SIZE,
                lastLoadedId
            );

            setIsLoading(false);

            if (!result?.error) {
                if (result.length > 0) {
                    setComments((prevRecords) => [...prevRecords, ...result]);
                }

                if (result.length < constants.DEFAULTS.PAGE_SIZE) {
                    setIsEndOfList(true);
                }
            } else {
                setIsUnavailable(true);
            }
        };
        fetchData();
    }, [lastLoadedId, childId, getItemComments, trigger]);

    // Sets comment with specified id for removal
    const handleDeleteComment = (id) => {
        const selected = records.find((record) => record.id === id);
        setCommentToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setCommentToDelete(null);
    };

    // Marks selected child as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (commentToDelete) {
            const result = await deleteComment(commentToDelete.id);

            if (!result?.error) {
                const updatedRecords = records.filter(
                    (record) => record.id !== commentToDelete.id
                );

                setComments(updatedRecords);
            }
        }

        setCommentToDelete(null);
    };

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setLastLoadedId(
            records?.length > 0 ? records[records.length - 1].id : 0
        );
    };

    return (
        <>
            <h3>{translations.Menu.Comments}</h3>

            {commentToDelete && (
                <ConfirmModal
                    contentComponent={
                        <ConfirmDeleteComment comment={commentToDelete} />
                    }
                    closeModal={handleCloseModal}
                    confirmDelete={handleDeleteComfirmed}
                />
            )}

            {records.map((record) => (
                <Comment
                    comment={record}
                    key={record.id}
                    deleteComment={handleDeleteComment}
                />
            ))}
            {isLoading && <Spinner />}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {!isLoading && !isEndOfList && (
                <div className="actions-center">
                    <button className="small" onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {currentUser && (
                <CommentForm
                    itemId={childId}
                    formSubmitted={handleFormSubmitted}
                />
            )}
        </>
    );
};

export default Comments;
