import React, { useState, useContext, useEffect } from "react";
import { translations, constants } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";
import { FeedbackApi } from "../../api/feedbackApi.js";

const FeedbackForm = ({ show, close }) => {
    const [isFormVisible, setIsFormVisible] = useState(show);
    const [inputs, setInputs] = useState({ content: "" });
    const [isShowSuccessMessage, setIsShowSuccessMessage] = useState(false);
    const [errorMessage, setError] = useState("");
    const { currentUser } = useContext(AuthContext);
    const { addFeedback } = FeedbackApi;

    useEffect(() => {
        setIsFormVisible(show);
    }, [show]);

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const checkContent = () => {
        return (
            inputs["content"].length >= constants.VALIDATION.MIN_CONTENT_SIZE
        );
    };

    const resetForm = () => {
        setError("");
        Object.keys(inputs).forEach((key) => {
            setInputs((prevInputs) => ({ ...prevInputs, [key]: "" }));
        });
    };

    const handleFormSubmit = async (e) => {
        e.target.disabled = true;
        e.preventDefault();

        if (!checkContent()) {
            setError(translations.Errors.ContentTooShort);
            return;
        }

        const result = await addFeedback(inputs.content, currentUser.id);

        if (!result?.error) {
            setIsShowSuccessMessage(true);
            const timer = setTimeout(() => {
                handleClose();
                setIsShowSuccessMessage(false);
            }, 1500);
            resetForm();
        } else {
            setError(result.error);
        }
    };

    const handleClose = (e) => {
        resetForm();
        setIsFormVisible(false);
        close();
    };

    return (
        <div>
            {isFormVisible && (
                <div className="overlay">
                    <div className="overlay-modal">
                        <div className="overlay-modal-content">
                            <p className="header">
                                {translations.Feedbacks.SendFeedback}
                            </p>
                            {!isShowSuccessMessage && (
                                <div className="form">
                                    <textarea
                                        name="content"
                                        onKeyUp={handleChange}
                                        autoFocus
                                    ></textarea>
                                    {errorMessage && (
                                        <div className="error">
                                            {errorMessage}
                                        </div>
                                    )}
                                </div>
                            )}

                            {!isShowSuccessMessage && (
                                <div className="controls">
                                    <button
                                        className="submit"
                                        onClick={handleFormSubmit}
                                        disabled={!checkContent()}
                                    >
                                        {translations.Forms.Submit}
                                    </button>
                                    <button
                                        className="close"
                                        onClick={handleClose}
                                    >
                                        {translations.Forms.Close}
                                    </button>
                                </div>
                            )}

                            {isShowSuccessMessage && (
                                <div className="success-message">
                                    {
                                        translations.Feedbacks
                                            .ThanksForYourFeedback
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeedbackForm;
