import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/authContext.js";
import GoogleAnalytics from "./components/helmet/GoogleAnalytics.jsx";
import DynamicMeta from "./components/helmet/DynamicMeta.jsx";
import { GA_ID } from "./base.js";
import { translations } from "./base.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <AuthContextProvider>
            <DynamicMeta
                title={translations.SiteTitle}
                description={translations.SiteDescription}
            />
            <GoogleAnalytics gaId={GA_ID} />
            <App />
        </AuthContextProvider>
    </>
);
