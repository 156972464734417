import { constants, translations } from "./../base.js";

export const getBadgeClass = (id) => {
    switch (id) {
        case constants.BADGES.USER_OF_THE_WEEK:
            return "purple";
        case constants.BADGES.USER_OF_THE_MONTH:
            return "pumpkin";
        case constants.BADGES.CHILD_OF_THE_WEEK:
            return "green";
        case constants.BADGES.BATTLE_WINNER:
            return "";
        case constants.BADGES.CONTEST_WINNER:
            return "blue";
        case constants.BADGES.DOUGLAS_ADAMS_42:
            return "";
        case constants.BADGES.VIP:
            return "";
        case constants.BADGES.USER_OF_THE_YEAR:
            return "yellow";
        case constants.BADGES.CHILD_OF_THE_YEAR:
            return "red";
        default:
            return "";
    }
};

export const getBadgeTitle = (id) => {
    switch (id) {
        case constants.BADGES.USER_OF_THE_WEEK:
            return translations.Badges.USER_OF_THE_WEEK;
        case constants.BADGES.USER_OF_THE_MONTH:
            return translations.Badges.USER_OF_THE_MONTH;
        case constants.BADGES.CHILD_OF_THE_WEEK:
            return translations.Badges.CHILD_OF_THE_WEEK;
        case constants.BADGES.BATTLE_WINNER:
            return translations.Badges.BATTLE_WINNER;
        case constants.BADGES.CONTEST_WINNER:
            return translations.Badges.CONTEST_WINNER;
        case constants.BADGES.DOUGLAS_ADAMS_42:
            return translations.Badges.DOUGLAS_ADAMS_42;
        case constants.BADGES.VIP:
            return translations.Badges.VIP;
        case constants.BADGES.USER_OF_THE_YEAR:
            return translations.Badges.USER_OF_THE_YEAR;
        case constants.BADGES.CHILD_OF_THE_YEAR:
            return translations.Badges.CHILD_OF_THE_YEAR;
        default:
            return translations.Badges.UNKNOWN;
    }
};
